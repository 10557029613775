import request from '../utils/request';

export interface SubmitAnswerDataType {
  questionId: string | number;
  answer: string;
  questionType: number;
  examId?: string | number;
  answerNum?: number;
  studentId: string | number;
}

/**
 * 发表评论
 */
export function add(actsWorkComment: any) {
  return request('/advert/actsWorkComment/add', {
    method: 'post',
    data: {
      actsWorkId: actsWorkComment.actsWorkId,
      content: actsWorkComment.content,
    },
  });
}
