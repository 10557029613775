import request from '../utils/request';

export interface SubmitAnswerDataType {
  // questionType: number;
  // examPaperId?: string | number;
  // studentId: string | number;
  courseId: string | number;
  workId: string | number;
  classId: null;
  sectionId: string | number;
  workAnswers: {};
}

/**
 * 首次获取作业内容
 * @param examId 考试id
 */
export function startDoHomework(courseId: number, chapterId: number, sectionId: number, workId: number) {
  return request('/studyCourse/startToWork', {
    params: {
      courseId,
      chapterId,
      sectionId,
      workId,
    }
  });
}

/**
 * 作业保存/提交
 */
export function submit(answer: any) {
  return request('/teachingWork/submit', {
    method: 'post',
    data: {
      courseId: answer.courseId,
      workId: answer.workId,
      classId: answer.classId,
      sectionId: answer.sectionId,
      workAnswers: answer.workAnswers,
    },
  });
}

/**
 * 已提交作业内容查询
 * @param id 我的作业列表项id
 */
export function studentAnswers(id: string | number) {
  return request(`teachingWork/studentWorkQuestion/${id}`);
}
