
import { Options, Vue } from 'vue-class-component';
// import { urlTranslate1 } from '@/utils/utils';

@Options({
  props: {
    onChange: Function,
    quesData: Object,
    workId: String,
    showAnswer: Boolean,
    studentAnswer: Boolean,
  },
  watch: {
    quesData() {
      this.content = '';
    },
  },
})
export default class QA extends Vue {
  content = '';
  quesData: ObjectT = {};
  workId = '';
  showAnswer!: boolean;
  studentAnswer!: boolean;
  onChange!: (type: number, totalSort: number, value: string) => {};
  created() {
    // this.quesData.content = urlTranslate1(this.quesData.content);
    this.content = this.quesData.answer ? this.quesData.answer : '';
    if (this.showAnswer) {
      this.content = this.quesData.answer ? this.quesData.answer : '';
    }
    if (this.studentAnswer) {
      this.content = this.quesData.answer ? this.quesData.answer : '';
    } else {
      this.content = localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId) ? JSON.parse((window as any).localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId))[this.quesData.id] : '';
      // this.content = '';
    }
  }
  // 处理内容change事件
  handleContentInput() {
    if (this.onChange) {
      this.onChange(4, this.quesData.totalSort, this.content);
    }
  }
}
