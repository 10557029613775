<template>
  <div class="bg-color">
    <div class="select-box">
      <div class="wrapper">
        <div class="box-card">
          <div class="box-line clearfix">
            <div class="text">学段：</div>
            <el-checkbox-group v-model="checkboxValue1" class="con" size="small" @change="checkboxChangeHandle(1, checkboxValue1)">
              <el-checkbox-button label="0">全部</el-checkbox-button>
              <el-checkbox-button v-for="item in termTags" :label="item.id" :key="item.id">
                {{ item.tagName }}
              </el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div class="box-line clearfix" style="padding-bottom: 0;">
            <div class="text">课程类型：</div>
            <el-checkbox-group v-model="checkboxValue2" class="con" size="small" @change="checkboxChangeHandle(2, checkboxValue2)">
              <el-checkbox-button label="0">全部</el-checkbox-button>
              <el-checkbox-button v-for="item in typeTags" :label="item.id" :key="item.id">
                {{ item.tagName }}
              </el-checkbox-button>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <div class="list-box">
      <div class="wrapper">
        <div>
          <el-row class="list" :gutter="30" v-if="listDatas.records && listDatas.records.length > 0">
            <el-col :span="8" v-for="o in listDatas.records" :key="o">
              <el-card :body-style="{ height: '180px',padding: '20px' }" class="course-card" @click="toCourseDetail(o.id)">
                <img width="179" height="246" :src="o.coverUrl ? o.coverUrl : empty" class="img" :onerror="`onerror=null;src='${empty}'`" />
                <el-row>
                  <el-col :span="12">
                    <!-- <img :src="o.coverUrl ? o.coverUrl : empty" class="courseImage" :onerror="`onerror=null;src='${empty}'`" /> -->
                  </el-col>
                  <el-col :span="12">
                    <div style="padding: 14px 0 14px 14px">
                      <div class="name">
                        <span>{{ o.courseName }}</span>
                      </div>
                      <div class="brief">
                        <span>{{ o.courseBrief }}</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-empty description="暂无数据" v-else></el-empty>
          <div class="pagination">
            <el-pagination
              @current-change="handleCurrentChange"
              v-model:currentPage="pageNo"
              background
              :page-size="pageSize"
              layout="prev, pager, next, jumper"
              :total="listDatas.total"
              class="page-item"
              v-if="listDatas.records && listDatas.records.length > 0"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import empty from '@/assets/img/empty.png';
import { CDNURL } from '@/utils/constants';
import { ElMessage } from 'element-plus';
import {renderFileUrl} from "@/utils/utils";
export default {
  data() {
    return {
      termArr: [], // 学段
      typeArr: [], // 课程类型
      termTagIds: '0',
      courseTypeTagIds: '0',
      pageNo: 1,
      pageSize: 6,
      courseData: { records: [] },
      checkboxValue1: ['0'], // 学段选中
      checkboxValue2: ['0'], // 课程类型选中
      listDatas: [],
      termTags: [],
      typeTags: [],
      empty: empty,
    };
  },
  mounted() {
    this.loadTags();
    this.getDatas();
  },
  methods: {
    // 获取标签字段信息
    loadTags: function() {
      const that = this;
      this.$request
        .get(`/studyCourse/loadTags?tagType=1`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.termTags = data;
        })
        .catch(function(error) {
          console.log(error);
        });
      this.$request
        .get(`/studyCourse/loadTags?tagType=2`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.typeTags = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取列表信息
    getDatas: function() {
      const that = this;
      this.$request
        .get(
          `/studyCourse/myCourse?pageNo=${this.pageNo}&pageSize=${this.pageSize}${this.termTagIds[0] !== '0' && this.termTagIds.length !== 0 ? '&termTagIds=' + this.termTagIds : ''}${this.courseTypeTagIds[0] !== '0' && this.courseTypeTagIds.length !== 0 ? '&courseTypeTagIds=' + this.courseTypeTagIds : ''}`,
        )
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.courseData1 = { ...data };
          for (let k in that.courseData1.records) {
            that.courseData1.records[k].coverUrl = renderFileUrl(CDNURL, that.courseData1.records[k].coverUrl);
          }
          that.listDatas = that.courseData1;// 交集
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // 分页操作
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getDatas();
    },

    // 条件筛选
    checkboxChangeHandle(line, val) {
      if (line === 1) {
        this.termArr = [];
        if (val.length === 0) this.termArr = ['0'];
        else this.inArray1(val);
        this.checkboxValue1 = this.termTagIds = this.termArr || '';
        this.pageNo2 = 1;
      }

      if (line === 2) {
        this.typeArr = [];
        if (val.length === 0) this.typeArr = ['0'];
        else this.inArray2(val);
        this.checkboxValue2 = this.courseTypeTagIds = this.typeArr || '';
        this.pageNo2 = 1;
      }
      this.getDatas();
    },

    inArray1(val){
      for (let i in val) {
        if (val[i] === '0') {
          if (val[0] === '0') continue;
          else {
            this.termArr = ['0'];
            break;
          }
        }
        this.termArr.push(val[i]);
      }
    },
    inArray2(val){
      for (let i in val) {
        if (val[i] === '0') {
          if (val[0] === '0') continue;
          else {
            this.typeArr = ['0'];
            break;
          }
        }
        this.typeArr.push(val[i]);
      }
    },

    // 跳转到详情页
    toCourseDetail(id) {
      // ElMessage.warning('该功能正在开发中');
      this.$router.push('/course/' + id);
    },
  },
};
</script>
<style lang="scss">
.select-box {
  .el-card {
    padding: 3px 18px;
  }
  .el-button {
    padding: 0 20px;
    min-height: 30px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
  }
  .box-line {
    .el-input {
      width: 295px;
      input {
        width: 295px;
        height: 28px;
        line-height: 28px;
        background-color: $bg-color-purle;
      }
    }
  }
}

.list-box {
  .list {
    .el-rate__icon {
      margin: 0;
    }
    .bottom {
      color: #999;
      height: 63px;
      padding-top: 5px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
  .course-card {
    transition: all 0.3s;
    transform: translateY(0);
    padding-top: 75px;
    position: relative;
    cursor: pointer;
    .card {
      border-radius: 10px;
      .image {
        width: 100%;
        height: 135px;
        display: block;
      }
    }
    &:hover {
      transform: translateY(3px);
    }
  }
  .el-card.is-always-shadow {
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
  }
}

.pagination {
  text-align: right;
  // padding-bottom: 50px;
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: $color-primary;
  }
}

.img {
  position: absolute;
  border-radius: 10px;
	left: 10px;
	top: 40px;
	z-index: 5;
}


</style>
<style lang="scss" scoped>
@import './index.scss';
</style>
