<template>
  <!-- <el-carousel indicator-position="outside" height="580px">
    <el-carousel-item v-for="(item, index) in bannerData" :key="item.id" :style="!index ? 'display:block' : 'none'">
      <a :href="item.skipUrl" target="_blank" class="home-banner"><img :src="item.pic" alt=""/></a>
    </el-carousel-item>
  </el-carousel> -->
  <div class="top" :style="`overflow: hidden;width: ${windowWidth}px;height: auto;`">
    <SwiperCarousel />
  </div>
  <!--logo替换-->
  <div class="box list-box" style="padding-top: 50px;">
    <div class="wrapper">
      <div class="tab-row">
        <span class="left-tab-title">
          最新资讯
        </span>
        <span class="right-more" @click="getMoreNews(1)">更多</span>
      </div>
      <el-row class="list" :gutter="20" v-if="newsDatas && newsDatas.length > 0">
        <el-col :span="8" v-for="o in newsDatas" :key="o">
          <news-card :data="o || {}"></news-card>
        </el-col>
      </el-row>
      <el-empty description="暂无数据" v-else></el-empty>
    </div>
  </div>
  <div class="box list-box">
    <div class="wrapper">
      <div class="tab-row">
        <span class="left-tab-title">
          活动资讯
        </span>
        <span class="right-more" @click="getMoreNews(2)">更多</span>
      </div>
      <el-row class="list" :gutter="20" v-if="casesDatas && casesDatas.length > 0">
        <el-col :span="8" v-for="o in casesDatas" :key="o">
          <news-card :data="o || {}"></news-card>
        </el-col>
      </el-row>
      <el-empty description="暂无数据" v-else></el-empty>
    </div>
  </div>
<!--  <div class="box list-box" style="padding-bottom: 25px;">-->
<!--    <div class="wrapper">-->
<!--      <div class="tab-row">-->
<!--        <span class="left-tab-title">-->
<!--          主题活动-->
<!--        </span>-->
<!--        <span class="right-more" @click="getMoreNews(3)">更多</span>-->
<!--      </div>-->
<!--      <el-row class="list" :gutter="20" v-if="actsDatas && actsDatas.length > 0">-->
<!--        <el-col :span="8" v-for="o in actsDatas" :key="o">-->
<!--          <news-card :data="o || {}"></news-card>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--      <el-empty description="暂无数据" v-else></el-empty>-->
<!--    </div>-->
<!--  </div>-->
</template>
<script>
import NewsCard from '@/components/public/newsCard.vue';
import SwiperCarousel from '@/components/public/swiper/SwiperCarousel.vue';
import { CDNURL } from '@/utils/constants';
import {renderFileUrl} from "@/utils/utils";
export default {
  components: {
    NewsCard,
    SwiperCarousel,
  },
  data() {
    return {
      newsDatas: [],
      casesDatas: [],
      actsDatas: [],
      activeName: '1',
      windowWidth: 0,
      windowHeight: 0
    };
  },
  mounted() {
      window.document.title = '锐思编程';
    // this.ssoLogin();
    this.getNewsData();
    this.windowWidth = document.body.scrollWidth;
    window.onresize = function() {
      this.windowWidth = document.body.scrollWidth;
      location.reload();
    };
    this.windowHeight = document.body.scrollHeight * 0.29;
    // logo替换
    // this.windowHeight = document.body.scrollHeight * 0.41;
  },
  methods: {
    // 单点登录
    ssoLogin: function () {
      let userData = localStorage.getItem('userInfo');
      if (userData) return ;
      const that = this;
      this.$request
        .post(`/studyStudent/ssoLogin`)
        .then(function(response) {
          const { data = {} } = response || {};
          if (data.code === 0) {
            const { data = {} } = response.data || {};
            const { userInfo = {} } = data;
            // localStorage.setItem('token', data.token);
            const userInfoData = {
              username: userInfo.username, // 账号
              realname: userInfo.realname, // 名字
              avatar: userInfo.avatar, // 头像
              sex: userInfo.sex, // 性别
              id: userInfo.id, // id
              school: userInfo.school, // 学校
              schoolClassName: userInfo.schoolClassName, // 班级
              studyNo: userInfo.studyNo, // 学号
              schoolGradeName: userInfo.schoolGradeName, // 年级
            };
            if (userInfoData.avatar) userInfoData.avatar = renderFileUrl(CDNURL, userInfoData.avatar);
            // localStorage.setItem('userInfo', JSON.stringify(userInfoData));
            userData = JSON.parse(userData);
            console.log('++++++++++++++++++++++', userData);
            localStorage.setItem('token', data.token);
            localStorage.setItem('userInfo', JSON.stringify(userInfoData));
            that.$router.go(0);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取资讯数据
    getNewsData: function() {
      const that = this;
      this.$request
        .get(`/studyInfo/homeNews`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          let newsData = data.news?.slice(0,3);
          let casesData = data.cases?.slice(0,3);
          let actsData = data.acts?.slice(0,3);

          for (let k in newsData) {
            newsData[k].coverUrl = renderFileUrl(CDNURL, newsData[k].coverUrl);
            newsData[k].type = 1;
          }

          for (let j in casesData) {
            casesData[j].coverUrl = renderFileUrl(CDNURL, casesData[j].coverUrl);
            casesData[j].type = 2;
          }

          for (let m in actsData) {
            actsData[m].coverUrl = renderFileUrl(CDNURL, actsData[m].coverUrl);
            actsData[m].type = 3;
          }

          that.newsDatas = newsData;
          that.casesDatas = casesData;
          that.actsDatas = actsData;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getMoreNews(type){
      this.$router.push({
        path: '/news',
        query: {
          type: type,
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import 'index.scss';
</style>
