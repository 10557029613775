<template>
  <div :class="$style.title">
    <div :class="$style.txt">
      <span :class="$style['bg-txt']">About Us</span>
      <h2>关于我们SLG</h2>
      <p>关于我们的副标题内容关于我们的副标题内容</p>
    </div>
  </div>
  <div class="wrapper">
    <h5 class="center-title">
      <p class="title-txt">协会简介</p>
      <p>Association profile</p>
    </h5>
    <div class="clearfix " :class="$style['item-box']">
      <img src="@/assets/img/about-img.png" alt="" />
      <div :class="$style.msg">
        <h6>简介标题</h6>
        <p :class="$style.eh">Robotic Science and Education Association of Fujian</p>
        <p>
          本机构是由全省从事教育、科研、科技推广的教师、专家学者及机器人爱好者自愿组成的全省性、专业性(学术科研)、非营利性社会团体。本团体认真执行国家有关社团管理的法律、法规，团结机器人科技工作者、爱好者和经营者，培养青少年的科技创新精神和动手实践能力，激励广大青少年机器人爱好者在机器人领域进行学习、探索、研究和实践，宣传普及我省机器人科教知识，提高机器人科教水平，传播、推广、和发展机器人科教，有效开展青少年机器人普及教育活动。
        </p>
      </div>
    </div>
  </div>
  <div :class="$style['box-purple']">
    <h5 class="center-title">
      <p class="title-txt">领导成员</p>
      <p>Leading members</p>
    </h5>
    <div class="wrapper">
      <el-row :gutter="18">
        <el-col :span="4" v-for="(o, index) in 6" :key="o" :offset="index > 0 ? 0 : 0">
          <el-card :body-style="{ padding: '0px' }" :class="$style['person-card']">
            <img
              src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
              :class="$style['image']"
            />
            <div style="padding: 14px;">
              <p :class="$style.name">撒大苏打</p>
              <p :class="$style.info">看见士大夫看见</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
  <div :class="$style.contact">
    <div :class="$style.txt">
      <h2>联系我们 <span>Contact us</span></h2>
      <p>
        总部：福州 <br />地址: 福建省福州市晋安区新店镇坂中路6号泰禾城市广场（二期）5#606 <br />电话: 13375916213
        <br />邮箱:97538788@qq.com
      </p>
    </div>
  </div>
</template>
<script></script>
<style lang="scss" module>
@import 'index.scss';
</style>
