
import { Options, Vue } from 'vue-class-component';
import { ElTag } from "element-plus";
// import { urlTranslate1 } from '@/utils/utils';
import { PROGRAMURL } from '@/utils/constants';

@Options({
  props: {
    onStartProgram: Function,
    onChange: Function,
    quesData: Object,
    showAnswer: Boolean,
    studentAnswer: Boolean,
    localAnswer: Object,
  },
})
export default class Program extends Vue {
  $store: any;
  token = localStorage.getItem('token'); // 是否登录
  onStartProgram!: (data: ObjectT) => {};
  quesData: ObjectT = {};
  showAnswer!: boolean;
  studentAnswer!: boolean;
  localAnswer: ObjectT = {};
  created() {
    // this.quesData.content = urlTranslate1(this.quesData.content);
    if (this.studentAnswer) {
      this.quesData.status = this.quesData.status ? 1 : 0;
    } else {
      this.quesData.status = 0;
    }
  }
  // 点击开始编程
  handleStartProgram() {
    if (this.onStartProgram) {
      this.onStartProgram(this.quesData);
    }
  }
  // 获取编程题类型名称
  getLangName(lang: number) {
    let langName = '';
    switch (lang) {
      case 1:
        langName = 'sc';
        break;
      case 2:
        langName = 'py';
        break;
      case 3:
        langName = 'cpp';
        break;
      default:
        break;
    }
    return langName;
  }
  // 查看代码
  handleViewCode(lang: number, ans: string, judgeId: any, timeLimit: any, memoryLimit: any) {
    const langName = this.getLangName(lang);
    const url = `${PROGRAMURL}${langName}/?platform=1&ide=${langName}&scene=view${judgeId ? '&judgeId=' + judgeId : ''}${timeLimit > 0 ? '&timeLimit=' + timeLimit : ''}${memoryLimit > 0 ? '&memoryLimit=' + memoryLimit : ''}${ans ? '&codePath=' + ans : ''}&token=${this.token}`;
    window.open(url, '_blank');
  }
}
