import { CDNURL } from '@/utils/constants';

// 时间位数格式化
export function singleTime2Double(time: number) {
  return time < 10 ? '0' + time : time;
}

// 时间格式化
export function formatTime(time: number) {
  let s: number | string = 0;
  let m: number | string = 0;
  let h: number | string = 0;
  if (time < 60) {
    s = time;
  } else if (time / 60 < 60) {
    s = time % 60;
    m = (time - s) / 60;
  } else {
    s = time % 60;
    m = ((time - s) / 60) % 60;
    h = (time - s - m * 60) / 60 / 60;
  }
  s = singleTime2Double(s);
  m = singleTime2Double(m);
  if (h) {
    h = singleTime2Double(h);
    return [h, m, s].join(':');
  }
  return [m, s].join(':');
}

// 文件后缀过滤
export function isFile(fileName: string) {
  // 获取文件的后缀名
  const suffix = fileName.substring(fileName.lastIndexOf('.') + 1);
  // 判断是否为需要的文件格式
  if (suffix === 'pdf' || ['docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx'].includes(suffix)) {
    return true;
  } else {
    return false;
  }
}

// 替换所有值为空值
export function replaceAll(text: string): any {
  let lastText = text;
  let checker = text.substring(text.indexOf('<img'), text.indexOf('<img') + text.substring(text.indexOf('<img')).indexOf('/>') + 2);
  text = text.replace(checker, '');
  if (lastText !== text) {
    return replaceAll(text);
  }
  return text;
}

// 富文本域名处理（1）
export function urlTranslate1(text: any) {
  if (text && text.includes('<img')) {
    return text.replace('../jeecgboot/sys/common/static/', CDNURL);
  }
  return text;
}

// 富文本域名处理（2）
export function urlTranslate2(text: any) {
  if (text && text.includes('<img')) {
    return text.replace('jeecgboot/sys/common/static/', CDNURL);
  }
  return text;
}

export function renderFileUrl(baseUrl: string, path: string) {
  if (path && path.includes('http')) {
    return path;
  }
  path = path.replace(/\/\//g, '/');
  return baseUrl + path;
}
