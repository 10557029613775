<template>
  <div :class="$style['bg-color-video']">
    <div :class="$style['video-nav']" class="video-nav">
      <div class="wrapper">
        <el-button @click="back">返回</el-button>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/course/' }">课程列表</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/course/' + courseId }">课程详情</el-breadcrumb-item>
          <el-breadcrumb-item>视频页</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div :class="$style['video-main']" class="video-main">
      <div class="wrapper">
        <el-card class="box-card">
          <div :class="$style['video-con']">
            <vue3-video-player :src="resourceSrc"></vue3-video-player>
          </div>
          <div :class="$style['video-drawer'] + (show ? ' ' + $style['show'] : '')">
            <span :class="$style['drawer-button']" @click="drawerChange"
              ><i :class="show ? 'el-icon-caret-right' : 'el-icon-caret-left'"></i
            ></span>
            <div :class="$style['drawer-list']">
              <h4>播放列表</h4>
              <el-collapse v-model="activeName">
                <el-collapse-item :title="lessonInfo.lessonName" name="1">
                  <ul class="list-ul">
                    <li
                      v-for="(item, index) in lessonInfo.resourceList"
                      :key="index"
                      :class="active === index ? 'active' : ''"
                      @click="playVideo(item.resourceSrc, index)"
                    >
                      <a>{{ item.resourceName }}</a>
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import { CDNURL } from '@/utils/constants';
import {renderFileUrl} from "@/utils/utils";
export default {
  data() {
    return {
      show: false,
      activeName: '1',
      host: CDNURL,
      courseId: this.$route.query.courseId,
      resourceSrc: renderFileUrl(CDNURL, this.$route.query.resourceSrc),
      lessonInfo: {},
      active: 0,
    };
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    getDatas() {
      const { courseId, lessonId, resourceSrc } = this.$route.query;
      const that = this;
      this.$request
        .get(`studyCourse/lessonVideoList?lessonId=${lessonId}&courseId=${courseId}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.lessonInfo = data;

          if (resourceSrc && resourceSrc !== '') {
            // 判断初始需要加active的行
            data.resourceList.map((item, index) => {
              if (item.resourceSrc.trim() === resourceSrc.trim()) {
                that.active = index;
              }
            });
          } else {
            that.playVideo(data.resourceList[0].resourceSrc, 0);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // 播放列表开关
    drawerChange() {
      this.show = !this.show;
    },

    // 点击播放视频
    playVideo(src, index) {
      this.active = index;
      this.resourceSrc = this.host + src;
    },

    // 点击返回按钮
    back() {
      this.$router.back(-1);
      // this.$router.push('/course/' + this.courseId);
    },
  },
};
</script>
<style lang="scss">
.video-nav {
  .el-button,
  .el-breadcrumb {
    float: left;
    margin-top: 30px;
    height: 30px;
    min-height: 30px;
    overflow: hidden;
    font-size: 14px;
  }
  .el-button {
    width: 77px;
    background-color: $bg-color-purle;
    color: $color-primary;
    text-align: center;
    border: none;
    margin-right: 25px;
    span {
      display: block;
    }
    line-height: 5px;
  }
  .el-breadcrumb {
    line-height: 30px;

    .el-breadcrumb__inner {
      color: $color-primary;
      font-weight: normal;
    }
    .el-breadcrumb__separator {
      color: $color-primary;
    }
  }
}
.video-main {
  video {
    width: 100%;
    background-color: #999;
  }
  .box-card {
    position: relative;
    overflow: hidden;
    .el-card__body {
      padding: 0;
    }
    .el-collapse-item {
      .el-collapse-item__header {
        padding: 0 10px 0 30px;
        height: 53px;
        line-height: 53px;
        background-color: #f4f4f4;
        color: #161c2d;
        i {
          float: left;
        }
      }
    }
  }
  .list-ul li.active {
    background-color: $bg-color-purle;
    color: $color-primary;
    cursor: pointer;
  }
}
</style>
<style lang="scss" module>
@import './index.scss';
</style>
