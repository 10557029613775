<template>
  <div class="bread-crumb">
    <el-breadcrumb separator="/" class="wrapper">
      <el-breadcrumb-item :to="{ path: '/news' }">资讯</el-breadcrumb-item>
      <el-breadcrumb-item>{{ detailData.title }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
  <div class="bg">
    <div class="wrapper">
      <el-card :class="$style['news-detail']" v-if="this.paramData.type === '1'">
        <article :class="$style['article']">
          <el-row :gutter="24">
            <el-col class="mb-8">
              <div :class="$style['news-title']">
                <h2>{{ detailData.title }}</h2>
                <el-divider style="margin: 12px 0;" />
                <p :class="$style['time']">发布日期：{{ $moment(detailData.auditTime).format('YYYY-MM-DD HH:mm') }}</p>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="mb-8">
              <div v-html="detailData.content"></div>
            </el-col>
          </el-row>
        </article>
      </el-card>
      <el-card :class="$style['news-detail']" v-if="this.paramData.type === '2'">
        <article :class="$style['article']">
          <el-row :gutter="24">
            <el-col :xs="{ span: 22, pull: 1, push: 1 }" :sm="{ span: 22, pull: 1, push: 1 }" :lg="18" :xl="19" :xxl="20"
                   class="mb-8">
              <div :class="$style['news-title']">
                <h2>{{ detailData.title }}</h2>
                <el-divider style="margin: 12px 0;" />
                <p :class="$style['time']">发布日期：{{ $moment(detailData.auditTime).format('YYYY-MM-DD HH:mm') }}</p>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :xs="{ span: 22, pull: 1, push: 1 }" :sm="{ span: 22, pull: 1, push: 1 }" :lg="18" :xl="19" :xxl="20"
                   class="mb-8">
              <div v-html="detailData.content"></div>
            </el-col>
            <el-col :xs="{ span: 22, pull: 1, push: 1 }" :sm="{ span: 22, pull: 1, push: 1 }" :lg="5" :xl="4" :xxl="3"
                   class="mb-8">
              <div :class="$style['news-filelist']">
                <DetailFileList :listdata="fileList" />
              </div>
            </el-col>
          </el-row>
        </article>
      </el-card>
      <el-card :class="$style['news-detail']" v-if="this.paramData.type === '3'">
        <div :class="$style['news-d-con']" class="clearfix">
          <div :class="$style['d-img']">
            <img
              :src="detailData.coverUrl ? renderFileUrl(cdnURL, detailData.coverUrl) : empty"
              class="image"
              :onerror="`onerror=null;src='${empty}'`"
            />
          </div>
          <div :class="$style['d-info']">
            <h3>{{ detailData.title }}</h3>
            <ul>
              <li :class="$style['d-left']" class="clearfix" style="margin-bottom: 20px;">
                <span>发布日期：{{ $moment(detailData.auditTime).format('YYYY-MM-DD') }}</span>
              </li>
              <li :class="$style['d-left']" class="clearfix">
                <span><svg-icon icon-class="iconeye" class="list-icon"></svg-icon> {{ detailData.browseTimes }}</span>
              </li>
              <li :class="$style['d-right']" class="clearfix">
                <span><svg-icon icon-class="iconshare" class="list-icon" :class="$style['list-icon']" @click="shareDialog"></svg-icon></span>
              </li>
              <el-dialog title="分享" v-model="visible1" width="30%" center custom-class="login-pop">
                <template #footer>
                  <el-button key="copy" type="primary" @click="handleCopy">复制</el-button>
                  <el-button key="close" @click="handleCancel">关闭</el-button>
                </template>
                <p>复制下方链接在浏览器打开：</p>
                <p id="url">{{ url }}</p>
              </el-dialog>
            </ul>
          </div>
        </div>
        <div class="new-tab">
          <div class="nav wrapper">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="活动详情" name="1"></el-tab-pane>
              <el-tab-pane label="资源下载" name="2"></el-tab-pane>
              <el-tab-pane :label="tabName" name="3"></el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div :class="$style['news-d-act']" v-if="activeName === '1'">
          <div v-html="detailData.content"></div>
        </div>
        <div :class="$style['news-d-act']" v-if="activeName === '2'">
          <div v-if="fileList && fileList.length > 0">
            <el-row v-for="item in fileList" v-bind:key="item.id">
              <el-col :span="18" style="font-size: 16px; margin: 0.75rem 0.75rem 0 0;">
                <a target="_blank" :href="item.url" class="a">
                {{ item.title }}</a>
              </el-col>
            </el-row>
          </div>
          <el-empty v-else image-size="64" description="暂无数据" />
        </div>
        <el-dialog title="评论" v-model="visible2" width="50%" center custom-class="login-pop">
          <div v-for="comment in commentDatas.records" :key="comment.id">
            <el-row class="act-list">
              <el-col :span="12">
                <span style="font-size: 16px;">{{ comment.fromUserId_dictText }}</span>
              </el-col>
              <el-col :span="12">
                <span style="font-size: 14px; float: right;">{{ comment.createTime }}</span>
              </el-col>
            </el-row>
            <el-row class="act-list" style="color: #8b949e;">
              {{ comment.content }}
            </el-row>
            <el-row class="act-list">
              <el-col :span="22"></el-col>
              <el-col :span="2">
                <el-button v-if="comment.fromUserId === userId" style="margin-left: 14px;" type="primary" size="small" @click="openDeleteDatas(comment.id)">删除</el-button>
              </el-col>
            </el-row>
            <el-divider style="margin: 0.5em 0 1em 0" />
          </div>
          <div class="pagination">
            <el-pagination
              @current-change="handleCurrentChange1"
              v-model:currentPage="pageNo1"
              background
              :page-size="pageSize1"
              layout="prev, pager, next, jumper"
              :total="commentDatas.total"
              class="page-item"
              v-if="commentDatas.records && commentDatas.records.length > 0"
            >
            </el-pagination>
          </div>
        </el-dialog>
        <el-dialog title="删除确认" v-model="visible3" width="400px" center custom-class="login-pop" :before-close="handleDeleteClose">
          <p>请确认是否要删除评论？</p>
          <template #footer>
            <el-button @click="handleDeleteClose">取 消</el-button>
            <el-button type="primary" @click="handleDelete">确 定</el-button>
          </template>
        </el-dialog>
        <el-dialog title="发表评论" v-model="visible4" width="40%" center custom-class="login-pop">
          <template #footer>
            <el-button type="primary" @click="handleSuccess()">确定</el-button>
            <el-button @click="handleCancel">关闭</el-button>
          </template>
          <el-input
            v-model="content"
            :rows="8"
            maxlength="300"
            show-word-limit
            type="textarea"
          />
        </el-dialog>
        <div :class="$style['news-d-act']" v-if="activeName === '3'">
          <div v-for="item in listDatas.records" :key="item.id">
            <el-row class="act-list">
              <el-col :span="12">
                <span style="font-size: 16px;">{{ item.workName }}</span>
              </el-col>
              <el-col :span="12">
                <span style="font-size: 15px; color: #666; float: right;">{{ item.school }} {{ item.className }} {{ item.author }}</span>
              </el-col>
            </el-row>
            <el-row class="act-list" style="color: #8b949e;">
              {{ item.brief }}
            </el-row>
            <div v-if="item.videoUrl?.length > 0">
              <el-row class="act-list" v-for="(video, index) in (item.videoUrl?.split(','))" :key="`video-${index}`">
                <el-col :span="18" style="margin-left: 50px;">
                  <a target="_blank" :href="resourceURL + video.split(':')[1]" class="a">
                    <span><svg-icon icon-class="iconvideo" class="list-icon" :class="$style['list-icon']"></svg-icon></span>
                    {{ video.split(':')[0] }}
                  </a>
                </el-col>
              </el-row>
            </div>
            <div v-if="item.filesUrl?.length > 0">
              <el-row class="act-list" v-for="(file, index) in (item.filesUrl?.split(','))" :key="`file-${index}`">
                <el-col :span="18" style="margin-left: 50px;">
                  <a target="_blank" :href="resourceURL + file.split(':')[1]" class="a">
                    <span><svg-icon icon-class="iconpaperclip" class="list-icon" :class="$style['list-icon']"></svg-icon></span>
                    {{ file.split(':')[0] }}
                  </a>
                </el-col>
              </el-row>
            </div>
            <el-row>
              <el-col :span="20"></el-col>
              <el-col :span="4">
                <a @click="commentDialog(item.id)" style="color: #8b949e;line-height: 32px;margin-left: 46px;">
                  <svg-icon icon-class="iconchat" class="list-icon" :class="$style['list-icon']" style="width: 20px; height: 20px;">
                  </svg-icon>
                </a>
                <span style="font-size: 16px; color: #8b949e; height: 32px; line-height: 32px;">{{ '（' + item.commentCount + '）' }}</span>
                <el-button type="primary" style="margin-left: 30px;" size="small" @click="addComment(item.id)"
                          >评论</el-button>
                </el-col>
            </el-row>
            <el-divider />
          </div>
          <div class="pagination">
            <el-pagination
              @current-change="handleCurrentChange2"
              v-model:currentPage="pageNo2"
              background
              :page-size="pageSize2"
              layout="prev, pager, next, jumper"
              :total="listDatas.total"
              class="page-item"
              v-if="listDatas.records && listDatas.records.length > 0"
            >
            </el-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import DetailFileList from '@/components/public/DetailFileList.vue';
import { CDNURL, RESOURCEURL } from '@/utils/constants';
import { ElMessage } from 'element-plus';
import * as commentService from '@/services/comment';
import {renderFileUrl} from "@/utils/utils";
export default {
  components: {
    DetailFileList,
  },
  data() {
    return {
      cdnURL: CDNURL,
      resourceURL: CDNURL,
      // 显示分享弹窗
      visible1: false,
      // 显示评论列表弹窗
      visible2: false,
      // 显示删除评论弹窗
      visible3: false,
      // 显示发表评论窗口
      visible4: false,
      content: '',
      url: '',
      activeName: '1',
      // 参数
      paramData: {
        id: '',
      },
      // 用户缓存id
      userId: '',
      // 评论分页选中
      pageNo1: 1,
      // 作品分页选中
      pageNo2: 1,
      // 评论分页条目数
      pageSize1: 5,
      // 作品分页条目数
      pageSize2: 5,
      // 文章数据
      detailData: {},
      tabName: '',
      fileList: [],
      listDatas: [],
      commentDatas: [],
      workId: 0,
      // 删除id
      deleteId: '',
    };
  },
  created() {
    const { params = {}, query = {} } = this.$route || {};
    this.paramData.id = params.id;
    this.paramData.type = query.type;
  },
  mounted() {
    this.getDatas();
    // this.getActDatas();
    this.url = window.location.href;
    if (localStorage.getItem('userInfo')) {
      this.userId = JSON.parse(localStorage.getItem('userInfo')).id;
    }
  },
  methods: {
      renderFileUrl,
    // 分享弹窗
    shareDialog() {
      this.visible1 = true;
    },
    // 评论列表弹窗
    commentDialog(id) {
      this.visible2 = true;
      this.workId = id;
      this.getCommentDatas();
    },
    // 复制确认
    handleCopy() {
      navigator.clipboard.writeText(this.url);
      ElMessage.success('复制成功！');
      this.visible1 = false;
    },
    // 删除评论弹窗
    openDeleteDatas(id) {
      this.deleteId = id;
      this.visible3 = true;
    },
    // 关闭删除评论弹窗
    handleDeleteClose() {
      this.visible3 = false;
      this.deleteId = '';
    },
    // 删除评论
    handleDelete() {
      const that = this;
      this.$request
        .delete(`advert/actsWorkComment/delete?id=${this.deleteId}`)
        .then(function() {
          that.visible3 = false;
          that.getActDatas();
          that.getCommentDatas();
        })
        .catch(function(error) {
          console.log(error);
        });
        ElMessage.success('删除成功');
    },
    // 评论确认
    handleSuccess() {
      const actsWorkComment = {
        actsWorkId: this.workId,
        content: this.content,
      };
      if (this.content === '') {
        ElMessage.warning('评论内容不能为空！');
      } else {
        const resp = commentService.add(actsWorkComment);
        this.handleCancel();
        resp.then(() => {
          this.getActDatas();
        })
        .catch(() => {
          // 取消
        });
      }
    },
    // 关闭弹窗（发表评论/评论列表）
    handleCancel() {
      this.visible1 = false;
      this.visible4 = false;
    },
    // 发表评论
    addComment(id) {
      // 判断是否登录
      if (localStorage.getItem('token') === null) {
        this.$store.commit('setLoginVisible', true);
        return;
      } else {
        this.workId = id;
        this.content = '';
        this.visible4 = true;
      }
    },
    // tab切换
    handleClick(tab) {
      if (tab.props.name === '3') {
        this.getActDatas();
      }
      this.activeName = tab.props.name;
    },
    // 评论分页操作
    handleCurrentChange1(val) {
      this.pageNo1 = val;
      this.getCommentDatas();
    },
    // 作品分页操作
    handleCurrentChange2(val) {
      this.pageNo2 = val;
      this.getActDatas();
    },
    getDatas() {
      const { id, type } = this.paramData;
      const that = this;
      this.$request
        .get(`/studyInfo/queryById?id=${id}&type=${type}&isNewBrowse=true`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.detailData = data;
          if (that.detailData.filesUrl) {
            that.detailData.filesUrl.split(',').forEach(item => {
              that.fileList.push({
                type: type,
                title: item.split(':')[0],
                url: renderFileUrl(CDNURL, item.split(':')[1]),
              });
            });
          }
          that.tabName = '优秀作品(' + that.detailData.workCount + ')';
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getActDatas() {
      const { id } = this.paramData;
      const that = this;
      this.$request
        .get(`/advert/actsWork/list?pageNo=${that.pageNo2}&pageSize=${that.pageSize2}&actsId=${id}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.listDatas = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getCommentDatas() {
      const that = this;
      this.$request
        .get(`/advert/actsWorkComment/list?pageNo=${that.pageNo1}&pageSize=${that.pageSize1}&actsWorkId=${that.workId}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.commentDatas = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss">
.a {
  color: $color-primary;
  word-wrap: break-word;
}
.act-list {
  line-height: 36px;
}
.el-dialog.share-pop {
  border-radius: 10px;
  overflow: hidden;
}
</style>
<style lang="scss" module>
@import './index.scss';
</style>
