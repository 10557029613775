<template>
  <el-card :class="$style['box-card']" class="clearfix" v-for="i in listDatas" v-bind:key="i.id">
    <router-link :to="`/exam/${i.id}?type=${paramData.type}`"
      ><img :src="i.pic ? renderFileUrl(CDNURL, i.pic) : empty" alt="" :class="$style['img']" :onerror="`onerror=null;src='${empty}'`"
    /></router-link>
    <div :class="$style['txt-item']">
      <h6>
        <!-- <router-link :to="`/exam/${i.id}?type=${paramData.type}`">{{ i.examName }}</router-link> -->
        {{ i.examName }}
      </h6>
      <p :class="$style['type']">{{ i.langName }}</p>
      <p :class="$style['msg']">开始时间：{{ i.startTime }}</p>
      <p :class="$style['msg']">考试时长：{{ i.examTime }} min</p>
      <!-- <el-button type="primary" :class="$style['in-btn']" size="small"
        ><router-link :to="`/exam/${i.id}?type=${tabParamData.type}`">参加考试</router-link></el-button
      > -->
      <el-button type="primary" :class="$style['in-btn']" size="small" @click="joinExam(i)">参加考试</el-button>
    </div>
  </el-card>
</template>
<script>
import empty from '@/assets/img/empty.png';
import { CDNURL } from '@/utils/constants';
import { ElMessage } from 'element-plus';
import {renderFileUrl} from "@/utils/utils";

export default {
  props: ['data', 'paramData'],
  data() {
    return {
      listDatas: this.data,
      tabParamData: this.paramData,
      // 空图片
      empty: empty,
      CDNURL: CDNURL,
    };
  },
  methods: {
      renderFileUrl,
    joinExam(i) {
      // 判断是否为移动端
      let browser = {
        versions: function() {
            let u = window.navigator.userAgent;
            return {
              trident: u.indexOf('Trident') > -1, // IE内核
              presto: u.indexOf('Presto') > -1, // opera内核
              webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
              gecko: u.indexOf('Firefox') > -1, // 火狐内核Gecko
              mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
              ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios
              android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android
              iPhone: u.indexOf('iPhone') > -1 , // iPhone
              iPad: u.indexOf('iPad') > -1, // iPad
              webApp: u.indexOf('Safari') > -1 // Safari
          };
        }()
      }
      let isPhone = browser.versions.mobile || browser.versions.ios || browser.versions.android || browser.versions.iPhone || browser.versions.iPad
      console.log('是否为移动端' +isPhone )
      // 检查浏览器
      const isChrome = window.navigator.userAgent.indexOf("Chrome") !== -1;
      console.log('isChrome', isChrome, window.navigator.userAgent)
      if (!isChrome || isPhone) {
        ElMessage.error('为保证考试正常进行，请使用谷歌浏览器进行考试！');
        return false;
      }

      let now = Date.parse(new Date())/1000;
      let start =  Date.parse(new Date(i.startTime))/1000;
      if (now < start) {
        ElMessage.error('考试未开始');
      } else {
        this.$router.push({path: `/exam/${i.id}?type=${this.tabParamData.type}`})
      }
    }
  },
  watch: {
    data: function() {
      this.listDatas = this.data;
    },
  },
};
</script>
<style lang="scss" module>
.box-card {
  margin-top: 60px;
  vertical-align: middle;
  .img {
    display: inline-block;
    width: 300px;
    height: 165px;
    border-radius: 10px;
    object-fit: cover;
    vertical-align: middle;
  }
  .txt-item {
    position: relative;
    display: inline-block;
    margin-left: 57px;
    width: 780px;
    vertical-align: middle;
    h6 {
      display: block;
      line-height: 44px;
      color: $color-primary;
      font-size: 18px;
      border-bottom: 1px solid $border-color;
      // transition: all 0.2s;
      // &:hover {
      //   opacity: 0.8;
      // }
    }
    .type {
      font-size: 22px;
      line-height: 56px;
    }
    .in-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      a {
        color: #fff;
      }
    }
  }
}
</style>
