<template>
  <div :class="$style['tabs']" class="course-tab">
    <div class="nav wrapper">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane :label="item.label" :name="item.name" v-for="item in navData" v-bind:key="item.name"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div class="wrapper">
    <el-card class="filter-box">
      <div class="filter-items clearfix" v-if="activeName === '1'">
        <div class="label">学段：</div>
        <el-checkbox-group v-model="checkboxValue1" class="filter-item" size="small" @change="checkboxChangeHandle(1, checkboxValue1)">
          <el-checkbox-button label="0">全部</el-checkbox-button>
          <el-checkbox-button v-for="item in termTags" :label="item.id" :key="item.id">
            {{ item.tagName }}
          </el-checkbox-button>
        </el-checkbox-group>
      </div>
      <div class="filter-items clearfix" v-if="activeName === '1'">
        <div class="label">课程类型：</div>
        <el-checkbox-group v-model="checkboxValue2" class="filter-item" size="small" @change="checkboxChangeHandle(2, checkboxValue2)">
          <el-checkbox-button label="0">全部</el-checkbox-button>
          <el-checkbox-button v-for="item in typeTags" :label="item.id" :key="item.id">
            {{ item.tagName }}
          </el-checkbox-button>
        </el-checkbox-group>
      </div>
      <div class="filter-items clearfix" v-else>
        <div class="label">课程名称：</div>
        <el-select
          v-model="courseId"
          placeholder="请选择课程"
          class="filter-select"
          size="small"
          @change="selectChangeHandle"
        >
          <el-option v-for="item in selectOptions.records" :key="item.id" :label="item.courseName" :value="item.id">
          </el-option>
        </el-select>
      </div>
    </el-card>
    <div class="table-list" v-if="activeName === '2'">
      <el-table
        stripe
        style="width: 100%"
        header-cell-style="background-color: #eaeffd;
                          text-align: center;
                          font-weight: bold;
                          color: #333;"
        :data="listDatas2.records"
        v-if="listDatas2.records && listDatas2.records.length > 0"
      >
        <el-table-column prop="numId" label="编号" width="50"></el-table-column>
        <el-table-column prop="courseName" label="课程名称" width="160"></el-table-column>
        <el-table-column prop="chapterName" label="章节名称" width="160"></el-table-column>
        <el-table-column prop="sectionName" label="课次名称" width="100"></el-table-column>
        <el-table-column prop="workName" label="作业名称" width="160"></el-table-column>
        <el-table-column prop="submitTime" label="提交时间" width="160"></el-table-column>
        <!-- <el-table-column prop="updateTime" label="修改时间" width="160"></el-table-column> -->
        <el-table-column prop="score" label="分数" width="100"> </el-table-column>
        <el-table-column prop="comment" label="点评" width="150"> </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="text" size="small" class="button" @click="toWorkPage(scope.row)">{{
              scope.row.status === 2 ? '查看答案' : '修改作业'
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-empty description="暂无数据" v-else></el-empty>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange1"
          v-model:currentPage="pageNo1"
          background
          :page-size="pageSize1"
          layout="prev, pager, next, jumper"
          :total="listDatas2.total"
          class="page-item"
          v-if="listDatas2.records && listDatas2.records.length > 0"
        >
        </el-pagination>
      </div>
    </div>
    <div class="list-box" v-else>
      <div class="wrapper">
        <div>
          <el-row class="list" :gutter="30" v-if="listDatas1.records && listDatas1.records.length > 0">
            <el-col :span="8" v-for="o in listDatas1.records" :key="o">
              <el-card :body-style="{ height: '180px',padding: '20px' }" class="course-card" @click="toCourseDetail(o.id)">
                <img width="179" height="246" :src="o.coverUrl" class="img" :onerror="`onerror=null;`" />
                <el-row>
                  <el-col :span="12">
                    <!-- <img :src="o.coverUrl ? o.coverUrl : empty" class="courseImage" :onerror="`onerror=null;src='${empty}'`" /> -->
                  </el-col>
                  <el-col :span="12">
                    <div style="padding: 14px 0 14px 14px">
                      <div class="name">
                        <span>{{ o.courseName }}</span>
                      </div>
                      <div class="brief">
                        <span>{{ o.courseBrief }}</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-empty description="暂无数据" v-else></el-empty>
          <div class="pagination">
            <el-pagination
              @current-change="handleCurrentChange2"
              v-model:currentPage="pageNo2"
              background
              :page-size="pageSize2"
              layout="prev, pager, next, jumper"
              :total="listDatas1.total"
              class="page-item"
              v-if="listDatas1.records && listDatas1.records.length > 0"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CDNURL } from '@/utils/constants';
import { ElMessage } from 'element-plus';
import {renderFileUrl} from "@/utils/utils";
export default {
  data() {
    return {
      termArr: [],
      typeArr: [],
      termTagIds: '0',
      courseTypeTagIds: '0',
      pageNo1: 1,
      pageNo2: 1,
      pageSize1: 10,
      pageSize2: 6,
      courseData: { records: [] },
      activeName: '1', // tab 选中
      checkboxValue1: ['0'], // 学段选中
      checkboxValue2: ['0'], // 课程类型选中
      // tab 数据
      navData: [
        { label: '课程列表', name: '1' },
        { label: '我的作业', name: '2' },
      ],
      // 课程数据
      selectOptions: [],
      // 标签数据
      termTags: [],
      typeTags: [],
      // 列表数据
      listDatas1: [],
      listDatas2: [],
      // 我的作业课程选中id
      courseId: '',
    };
  },
  watch: {
      '$route'(to, from) {
          this.changeTab()
      }
  },
  created() {
    const { query = {} } = this.$route || {};
    this.activeName = query.type || '1';
  },
  mounted() {
    console.log("here", this.activeName)
    this.changeTab()
  },
  methods: {
    changeTab() {
        const { query = {} } = this.$route || {};
        this.activeName = query.type || '1';
        this.tagType = 2;
        this.checkboxValue1 = ['0'];
        this.checkboxValue2 = ['0'];
        if (this.activeName === '2') {
            this.getCourseDatas();
        } else {
            this.getTagDatas();
            this.getDatas();
        }
    },
    // 获取标签数据
    getTagDatas() {
      const that = this;
      this.$request
        .get(`/studyCourse/loadTags?tagType=1`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.termTags = data;
        })
        .catch(function(error) {
          console.log(error);
        });
      this.$request
        .get(`/studyCourse/loadTags?tagType=2`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.typeTags = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取课程数据
    getDatas() {
      const that = this;
      this.$request
        .get(
          `/studyCourse/myCourse?pageNo=${this.pageNo2}&pageSize=${this.pageSize2}${this.termTagIds[0] !== '0' && this.termTagIds.length !== 0 ? '&termTagIds=' + this.termTagIds : ''}${this.courseTypeTagIds[0] !== '0' && this.courseTypeTagIds.length !== 0 ? '&courseTypeTagIds=' + this.courseTypeTagIds : ''}`,
        )
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.courseData = { ...data };
          for (let k in that.courseData.records) {
            that.courseData.records[k].coverUrl = renderFileUrl(CDNURL, that.courseData.records[k].coverUrl);
          }
          that.listDatas1 = that.courseData;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取作业数据
    getWorkDatas() {
      const that = this;
      this.$request
        .get(`/studyCourse/myHomeWork?pageNo=${this.pageNo1}&pageSize=${this.pageSize1}${this.courseId ? '&courseId=' + this.courseId : ''}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          const newDatas = data.records.map((item, index) => {
            item.numId = index + 1;
            return item;
          });
          that.listDatas2 = { ...data, records: newDatas };
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 获取课程下拉数据
    getCourseDatas() {
      const that = this;
      this.$request
        .get(`/studyCourse/myCourse?pageNo=1`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.selectOptions = { ...data };
          that.getWorkDatas();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    handleCurrentChange1(val) {
      this.pageNo1 = val;
      this.getWorkDatas();
    },
    handleCurrentChange2(val) {
      this.pageNo2 = val;
      this.getDatas();
    },
    handleTabClick(tab) {
      this.activeName = tab.props.name;
      this.pageNo1 = 1;
      this.pageNo2 = 1;
      this.checkboxValue1 = ['0'];
      this.checkboxValue2 = ['0'];
      this.courseId = '';
      console.log("tab.props.name", tab.props.name);
      if (tab.props.name === '2') {
        this.getCourseDatas();
      } else {
        this.getDatas();
      }
    },
    checkboxChangeHandle(line, val) {
      if (line === 1) {
        this.termArr = [];
        if (val.length === 0) this.termArr = ['0'];
        else this.inArray1(val);
        this.checkboxValue1 = this.termTagIds = this.termArr || '';
        this.pageNo2 = 1;
      }
      if (line === 2) {
        this.typeArr = [];
        if (val.length === 0) this.typeArr = ['0'];
        else this.inArray2(val);
        this.checkboxValue2 = this.courseTypeTagIds = this.typeArr || '';
        this.pageNo2 = 1;
      }
      this.getDatas();
    },
    inArray1(val){
      for (let i in val) {
        if (val[i] === '0') {
          if (val[0] === '0') continue;
          else {
            this.termArr = ['0'];
            break;
          }
        }
        this.termArr.push(val[i]);
      }
    },
    inArray2(val){
      for (let i in val) {
        if (val[i] === '0') {
          if (val[0] === '0') continue;
          else {
            this.typeArr = ['0'];
            break;
          }
        }
        this.typeArr.push(val[i]);
      }
    },
    selectChangeHandle(v) {
      this.pageNo1 = 1;
      this.getWorkDatas();
    },
    // 跳到详情
    toWorkPage(row) {
      // ElMessage.warning('该功能正在开发中');
      if (row.status === 2) {
        window.open('/course/homework/done?courseId='+row.courseId+'&sectionId='+row.sectionId+'&workId='+row.workId+'&id='+row.id); // 查看答案
      } else {
        window.open('/course/homework/homeworkpaper/?courseId='+row.courseId+'&sectionId='+row.sectionId+'&workId='+row.workId+'&id='+row.id); // 修改作业
      }
    },
    toCourseDetail(id) {
      this.$router.push(`/course/${id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.table-list {
  min-height: 400px;
  .el-table {
    margin-bottom: 0;
  }
}
</style>
<style lang="scss" module>
@import 'index.scss';
</style>
