
import { Options, Vue } from 'vue-class-component';
// import { urlTranslate1, urlTranslate2 } from '@/utils/utils';

@Options({
  props: {
    onChange: Function,
    quesData: Object,
    workId: String,
    showAnswer: Boolean,
    studentAnswer: Boolean,
  },
  watch: {
    quesData() {
      this.radio = '';
    },
  },
})
export default class SingleChoice extends Vue {
  quesData: ObjectT = {};
  workId = '';
  onChange!: (type: number, totalSort: number, value: string) => {};
  showAnswer!: boolean;
  studentAnswer!: boolean;
  radio = '';
  created() {
    // this.quesData.content = urlTranslate1(this.quesData.content);
    // this.quesData.optionA = urlTranslate2(this.quesData.optionA);
    // this.quesData.optionB = urlTranslate2(this.quesData.optionB);
    // this.quesData.optionC = urlTranslate2(this.quesData.optionC);
    // this.quesData.optionD = urlTranslate2(this.quesData.optionD);
    // this.quesData.optionE = urlTranslate2(this.quesData.optionE);
    // this.quesData.optionF = urlTranslate2(this.quesData.optionF);
    if (this.showAnswer) {
      this.radio = this.quesData.answer ? this.quesData.answer : '';
    }
    if (this.studentAnswer) {
      this.radio = this.quesData.answer ? this.quesData.answer : '';
    } else {
      this.radio = localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId) ? JSON.parse((window as any).localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId))[this.quesData.id] : '';
      // this.radio = '';
    }
  }
  // 处理选项change
  handleOptionChange(value: string) {
    if (this.showAnswer) {
      this.radio = this.quesData.answer ? this.quesData.answer : '';
      return;
    }
    if (this.onChange) {
      this.onChange(1, this.quesData.totalSort, value);
    }
  }
}
