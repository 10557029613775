<template>
  <el-card :body-style="{ padding: '20px', height: '324px' }" class="card" @click="toCourseDetail(dataList.id)" :data-id="dataList.id">
    <el-row>
      <el-col>
        <img :src="dataList.coverUrl ? dataList.coverUrl : empty" class="newsImage" :onerror="`onerror=null;src='${empty}'`" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-row style="margin-top: 10px;">
          <el-col :span="17">
            <div class="title">{{ dataList.title }}</div>
          </el-col>
          <el-col :span="7">
            <div class="time">{{ $moment(dataList.auditTime).format('YYYY-MM-DD') }}</div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 8px;">
          <div class="content" v-html="dataList.content"></div>
        </el-row>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import empty from '@/assets/img/empty.png';
import { replaceAll } from '@/utils/utils';
import { ElMessage } from 'element-plus';
export default {
  props: ['data'],
  data() {
    return {
      // 数据
      dataList: this.data,
      // 空图片
      empty: empty,
    };
  },
  mounted() {
    if (this.dataList.content && this.dataList.content.includes('<img')) {
      this.dataList.content = replaceAll(this.dataList.content);
    }
  },
  methods: {
    toCourseDetail(id) {
      this.$router.push({
        path: `/news/${id}`,
        query: {
          type: this.dataList.type,
        }
      });
    },
  },
};
</script>
<style>
.title {
  padding: 10px 0 0 10px;
  font-size: 15px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.time {
  padding: 10px 0 0 10px;
  font-size: 15px;
  color: #999;
}
.content {
  padding: 0 0 0 10px;
  color: #777;
  letter-spacing: 1px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-height: 25px;
  font-size: 13px;
  overflow: hidden;
}
</style>