import request from '../utils/request';

/**
 * 添加作品
 */
export function add(coverKey: any, projectKey: any, projectTitle: any, projectType: number) {
  return request('/studyCreate/add', {
    method: 'post',
    data: {
      coverUrl: coverKey,
      workUrl: projectKey,
      workName: projectTitle,
      workType: projectType,
    },
  });
}

/**
 * 编辑作品
 */
export function edit(coverKey: any, projectKey: any, projectTitle: any, workId: any, projectType: number) {
    return request('/studyCreate/edit', {
      method: 'post',
      data: {
        coverUrl: coverKey,
        workUrl: projectKey,
        workName: projectTitle,
        id: workId,
        workType: projectType,
      },
    });
  }
