<template>
  <div :class="$style.title">
    <div :class="$style.txt">
      <h2 style="visibility:hidden;">资讯</h2>
      <p style="visibility:hidden;">关于我们的副标题内容关于我们的副标题内容</p>
    </div>
  </div>
  <div class="new-tab">
    <div class="wrapper nav">
      <el-tabs v-model="paramData.type" @tab-click="handleClick">
        <el-tab-pane v-for="item in newsTab" v-bind:key="item.name" :label="item.name" :name="item.value"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div class="new-box">
    <div class="wrapper">
      <el-tabs tab-position="left" class="news-tabs" v-model="activeName" @tab-click="handleColumnClick">
        <el-tab-pane v-for="(data, index) in newsColumn" v-bind:key="data.columnName" :label="data.columnName" :name="index">
          <div v-if="newsData.records && newsData.records.length > 0">
            <el-row class="news-list" v-for="item in newsData.records" :key="item">
              <el-col :span="18">
                <!-- <router-link :to="`/news/${item.id}`" class="txt">{{ item.title }}</router-link> -->
                <a class="txt" @click="toDetail(item.id)">{{ item.title }}</a>
              </el-col>
              <el-col :span="4" :offset="2"
                ><div class="time">{{ $moment(item.auditTime).format('YYYY-MM-DD') }}</div></el-col
              >
            </el-row>
          </div>
          <el-empty description="暂无数据" v-else></el-empty>
          <div class="pagination">
            <el-pagination
              @current-change="handleCurrentChange"
              v-model:currentPage="pageNo"
              background
              :page-size="pageSize"
              layout="prev, pager, next, jumper"
              :total="newsData.total"
              class="page-item"
              v-if="newsData.records && newsData.records.length > 0"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import empty from '@/assets/img/empty.png';
import { CDNURL } from '@/utils/constants';
import { ElMessage } from 'element-plus';
export default {
  data() {
    return {
      // 参数
      paramData: {
        type: '1',
      },
      newsTab: [
        { name: '最新资讯', value: '1', label: 'advertNews' },
        { name: '活动资讯', value: '2', label: 'typicalCases' },
        // { name: '主题活动', value: '3', label: 'themeActivities' },
      ],
      activeName: 0,
      newsColumn: [],
      columnId: '1597340546419511298',
      newsData: [],
      scratchDatas: [],
      // 分页
      pageNo: 1,
      // 分页条目数
      pageSize: 5,
      // 列表数据
      listDatas: {
        records: [],
        total: 0,
      },
      // 空图片
      empty: empty,
    };
  },
  created() {
    const { query = {} } = this.$route || {};
    this.paramData.type = query.type || '1';
  },
  mounted() {
    this.getColumnData();
  },
  methods: {
    getColumnData: function() {
      const that = this;
      const module = this.newsTab[this.paramData.type-1].label;
      this.$request
        .get(`/studyInfo/moduleColumn?module=${module}`)
        .then(function(response) {
          that.newsColumn = response.data.data;
          that.columnId = that.newsColumn ? that.newsColumn[0]?.id : null;
          that.getNewsData();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getNewsData: function() {
      const that = this;
      const { type } = this.paramData || {};
      const columnId = this.columnId;
      this.$request
        .get(`/studyInfo/news?pageNo=${this.pageNo}&pageSize=${this.pageSize}&type=${type}&columnId=${columnId}`)
        .then(function(response) {
          that.newsData = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    handleClick(tab) {
      this.paramData.type = tab.props.name;
      this.getColumnData();
    },
    handleColumnClick(tab) {
      this.columnId = this.newsColumn[tab.props.name].id;
      this.pageNo = 1;
      this.getNewsData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getNewsData();
    },
    toDetail(id){
      const { type } = this.paramData || {};
      this.$router.push({
        path: `/news/${id}`,
        query: {
          type: type,
        }
      });
    }
  },
};
</script>
<style lang="scss">
.news-list {
  .el-card__body {
    padding: 0;
  }
  .el-card {
    border-radius: 10px;
  }
}
</style>
<style lang="scss" module>
@import 'index.scss';
</style>
