<template>
  <div :class="$style['tabs']" class="user-tab">
    <div class="nav wrapper">
      <el-tabs v-model="activeName">
        <el-tabs v-model="activeName">
          <el-tab-pane
            :label="item.label"
            :name="item.name"
            v-for="item in navData"
            v-bind:key="item.name"
          ></el-tab-pane>
        </el-tabs>
      </el-tabs>
    </div>
  </div>
  <div class="bg">
    <div class="wrapper">
      <el-card :class="$style['user-box']">
        <h6 :class="$style['user-header']">
          <span>基本信息</span>
        </h6>
        <div :class="$style['user-password']">
          <p :class="$style['password-label']" style="padding: 0 50px 180px;">
            <span style="float: left;">头像：</span>
            <span :class="$style['user-form-item']" style="float: left;">
              <img
                :class="$style['user-upload-img']"
                :src="userForm.avatar ? userForm.avatar : defaultImg"
                :onerror="`onerror=null;src='${defaultImg}'`"
              />
              <el-upload
                list-type="picture-card"
                class="user-upload"
                :class="$style['user-upload-active']"
                :action="api"
                :headers="{ Authorization: getAuth }"
                :data="bizPath"
                :on-success="handleUploadSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <template #default>
                  修改
                </template>
              </el-upload>
            </span>
          </p>
          <p :class="$style['password-label']">
            <span>姓名：</span> <span :class="$style['password-txt']">{{userForm.realname}}</span>
          </p>
          <p :class="$style['password-label']">
            <span>性别：</span> <span :class="$style['password-txt']">{{userForm.sex}}</span>
          </p>
<!--          <p :class="$style['password-label']">-->
<!--            <span>学号：</span> <span :class="$style['password-txt']">{{userForm.studyNo}}</span>-->
<!--          </p>-->
          <p :class="$style['password-label']">
            <span>学校：</span> <span :class="$style['password-txt']">{{userForm.school}}</span>
          </p>
<!--          <p :class="$style['password-label']">-->
<!--            <span>年级：</span> <span :class="$style['password-txt']">{{userForm.schoolGradeName}}</span>-->
<!--          </p>-->
<!--          <p :class="$style['password-label']">-->
<!--            <span>班级：</span> <span :class="$style['password-txt']">{{userForm.schoolClassName}}</span>-->
<!--          </p>-->
          <p :class="$style['password-label']">
            <span>账号：</span> <span :class="$style['password-txt']">{{userForm.username}}</span>
          </p>
          <p :class="$style['password-label']">
            <span>密码：</span> <span :class="$style['password-txt']">已设置密码</span>
            <el-button type="primary" size="small" :class="$style['edit-passwprd']" class="edit-passwprd" @click="showEditPop"
              >修改密码</el-button
            >
          </p>
        </div>
      </el-card>
    </div>
    <el-dialog
      title="修改密码"
      v-model="editVisible"
      width="500px"
      :before-close="closeFormHandle"
      :show-close="false"
      center
      custom-class="login-pop"
    >
      <p :class="$style['edit-msg']">为了保证你的账号安全，密码至少8个字符，<br />必须包含大小写字母、数字及特殊符号</p>
      <el-form :model="editRuleForm" :rules="editRules" ref="editRuleForm" status-icon>
        <el-form-item prop="oldPassword">
          <el-input
            v-model="editRuleForm.oldPassword"
            autocomplete="off"
            size="medium"
            type="password"
            placeholder="请输入旧密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input
            v-model="editRuleForm.newPassword"
            autocomplete="off"
            size="medium"
            placeholder="请输入新密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input
            v-model="editRuleForm.confirmPassword"
            autocomplete="off"
            size="medium"
            type="password"
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeFormHandle">取 消</el-button>
          <el-button type="primary" @click="submitEditForm('editRuleForm')">提 交</el-button>
        </span>
        <p :class="$style['edit-msg']">修改密码后，需要重新登录账号</p>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import defaultImg from '@/assets/img/user.png';
import { ElMessage } from 'element-plus';
import { RESOURCEURL, CDNURL } from '@/utils/constants';
import {renderFileUrl} from "@/utils/utils";
export default {
  data() {
    // 新密码验证
    const validateNewPassword = (rule, value, callback) => {
      let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!regex.test(value)) {
        callback(new Error('必须包含大小写字母、数字及特殊符号!'));
      } else {
        if (this.editRuleForm.confirmPassword !== '') {
          this.$refs.editRuleForm.validateField('checkPass');
        }
        callback();
      }
    };
    // 确认密码验证
    const validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.editRuleForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      // tab选中
      activeName: '1',
      // tab数据
      navData: [
        { label: '个人信息', name: '1' },
      ],
      // 用户数据
      userForm: {
        avatar: '',
        realname: '',
        sex: '',
        studyNo: '',
        school: '',
        schoolClassName: '',
        schoolGradeName: '',
      },
      dialogImageUrl: '',
      dialogVisible: false,
      // 上传请求URL
      api: RESOURCEURL + '/sys/common/upload',
      // 上传额外参数
      bizPath: {biz: 'student/pic'},
      // 修改密码弹窗
      editVisible: false,
      // 密码字段
      editRuleForm: {
        newPassword: '',
        confirmPassword: '',
        oldPassword: '',
      },
      // 密码规则
      editRules: {
        newPassword: [
          {required: true, message: '请输入新密码', trigger: 'blur', },
          {
            min: 8,
            trigger: 'blur',
            message: '密码至少8个字符',
          },
          { validator: validateNewPassword, trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: validateConfirmPassword, trigger: 'blur' },
        ],
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
      },

      // 默认头像
      defaultImg: defaultImg,
      cdnUrl: CDNURL,
    };
  },
  mounted() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
    userInfo.sex = userInfo.sex === 1 ? '男' : '女';
    this.userForm = userInfo;
    console.log(JSON.parse(localStorage.getItem('userInfo')));
  },
  computed: {
    getAuth() {
      return localStorage.getItem('token') || '';
    },
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleRemove() {
      this.dialogVisible = true;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 修改密码
    submitEditForm(formName) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request
            .put(`/studyStudent/editPassword`, { ...this.editRuleForm })
            .then(function() {
              localStorage.clear();
              that.$router.go(0);
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    // 打开弹窗
    showEditPop() {
      this.editVisible = true;
    },
    // 关闭弹窗
    closeFormHandle() {
      this.editVisible = false;
    },
    handleUploadSuccess(e) {
      if (e.code === 0) {
        const that = this;
        this.userForm.avatar = e.msg;
        this.$request
          .put(`/studyStudent/edit`, { avatar: this.userForm.avatar })
          .then(function(res) {
            const { data = {} } = res || {};
            if (data.code === 0) {
              const userInfoData = JSON.parse(localStorage.getItem('userInfo'))
              userInfoData.avatar = renderFileUrl(CDNURL, that.userForm.avatar); // 头像
              localStorage.setItem('userInfo', JSON.stringify(userInfoData));
              that.$router.go(0);
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      }
      if (e.code === 10002) {
        localStorage.clear();
        window.location.reload();
      }
    },
    // 上传格式验证
    beforeAvatarUpload(file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 0.5;
      if (!isImage) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 500KB!');
      }
      return isImage && isLt2M;
    },
  },
};
</script>
<style lang="scss">
.user-tab {
  .el-tabs__active-bar.is-top {
    background-color: $color-primary;
  }
  .el-tabs__item:hover {
    color: $color-primary;
    cursor: pointer;
  }
  .el-tabs__item.is-active {
    color: $color-primary;
  }
}
.user-upload {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  display: none;
  .el-upload--picture-card {
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 120px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .el-upload-list {
    display: none;
  }
}
</style>
<style lang="scss" module>
@import 'index.scss';
</style>
