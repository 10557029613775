
import QuestionDetailHead from '@/components/panels/QuestionDetailHead.vue';
import AnswerTopTitle from '@/components/panels/AnswerTopTitle.vue';
import AnswerHead from '@/components/panels/AnswerHead.vue';
import AnswerAnalysis from '@/components/panels/AnswerAnalysis.vue';
import CommitHistory from '@/components/panels/CommitHistory.vue';
import AnswerPageFoot from '@/components/panels/AnswerPageFoot.vue';

import { Options, Vue } from 'vue-class-component';
import { ElMessage } from 'element-plus';
import { PROGRAMURL } from '@/utils/constants';
import { studentAnswers } from '@/services/homework';

@Options({
  props: {
    isSingQuesMod: Boolean, // 是否是单题目模式（题库）
    fixed: Boolean,
    title: String,
    questionTypeName: String,
    time: String,
    analysisData: Object,
    historyData: Object,
    showAnalysis: Boolean,
    showHistory: Boolean,
    quesData: Object,
    onChange: Function,
    onClose: Function,
    onViewAnalysis: Function,
    onViewHistory: Function,
    onRandom: Function,
    onPrev: Function,
    onNext: Function,
    onDeleteCommitHistory: Function,
    onSave: Function,
    lang: Number,
    studentAnswer: Boolean,
    localAnswer: Object,
  },
  components: {
    AnswerTopTitle,
    QuestionDetailHead,
    AnswerHead,
    AnswerAnalysis,
    CommitHistory,
    AnswerPageFoot,
  },
})
export default class Program extends Vue {
  $refs: ObjectT = {};
  $store: any;
  time!: string;
  $confirm: any;
  quesData: ObjectT = {};
  onChange!: () => {};
  onClose!: () => {};
  onRandom!: () => {};
  onPrev!: () => {};
  onNext!: () => {};
  onSave!: (type: number, totalSort: number, value: string) => {};
  pageBodyHeight = 0;
  showQuestionContent = false; // 是否显示题目
  studentAnswer!: boolean;
  localAnswer: ObjectT = {};
  lang!: number; // 2=scratch,3=python
  langName = '';
  isSave = false;
  token = localStorage.getItem('token');
  PROGRAMURL = PROGRAMURL;
  loading = false;
  addStudyTimeTimer: any = null; // 添加学习时间定时器

  mounted() {
    this.setLangName();
    this.calcPageBodyHeight();
    window.addEventListener('resize', () => {
      this.calcPageBodyHeight();
    });

    const that = this;
    this.addStudyTimeTimer = setInterval(() => {
      that.$refs.iframe.contentWindow.addStudyTime();
    }, 60000);
  }

  beforeUnmount() {
    clearInterval(this.addStudyTimeTimer);
  }

  // 设置编程题类型名称
  setLangName() {
    switch (this.quesData.codingEnv) {
      case 1:
        this.langName = 'sc';
        break;
      case 2:
        this.langName = 'py';
        break;
      case 3:
        this.langName = 'cpp';
        break;
      default:
        break;
    }
  }

  // 计算主体页面高度
  calcPageBodyHeight() {
    const pageHead: any = this.$refs.pageHead;
    const pageFoot: any = this.$refs.pageFoot ? this.$refs.pageFoot.$el : null;
    const pageHeadH = pageHead ? pageHead.getBoundingClientRect().height : 0;
    const pageFootH = pageFoot ? pageFoot.getBoundingClientRect().height : 0;
    this.pageBodyHeight = document.body.clientHeight - pageHeadH - pageFootH - 18;
  }

  // 处理面板关闭
  handleClose() {
    this.handleSave(this.onClose);
  }

  // 处理题目内容显示
  handleViewQuestion() {
    this.showQuestionContent = !this.showQuestionContent;
  }

  // 处理下一题
  // 处理点击随机一题
  handleRandomQuestion() {
    if (this.onRandom) {
      this.onRandom();
    }
    this.showQuestionContent = true;
  }
  // 处理点击上一题
  handlePrevQuestion() {
    if (this.onPrev) {
      this.onPrev();
    }
    this.showQuestionContent = true;
  }
  // 处理点击下一题
  handleNextQuestion() {
    if (this.onNext) {
      this.onNext();
    }
    this.showQuestionContent = true;
  }

  // 处理保存
  handleSave(successCb: () => {}) {
    this.loading = true;
    const that = this;
    this.$refs.iframe.contentWindow.uploadCode((data: ObjectT) => {
      this.loading = false;
      if (data.code === 10002) {
        ElMessage.error(data.msg ? data.msg : '保存失败');
        this.$store.commit('setLoginVisible', true);
        return;
      }
      if (data.code || data.code === 10003) {
        ElMessage.error(data.msg ? data.msg : '保存失败，请重试！');
        return;
      }
      this.isSave = true;
      if (successCb instanceof Function) {
        successCb();
      } else {
        ElMessage.success('保存成功');
        that.onClose();
      }
      if (this.onSave) {
        console.log(">>>>>>>>>", data);
        this.onSave(5, this.quesData.totalSort, data.msg);
      }
    });
  }
}
