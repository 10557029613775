import request from '../utils/request';

/**
 * 满意度
 */
export function set(courseKey: any, value: number) {
  return request('/studyCourse/setFeedback', {
    method: 'put',
    data: {
      courseId: courseKey,
      value: value,
    },
  });
}
