<template>
  <div :class="$style['bg-color']" style="padding-top:0;">
    <div :class="$style['top-bg']">
      <div class="wrapper">
        <el-breadcrumb separator="/" class="wrapper breadcrumb" :class="$style['breadcrumb']">
          <el-breadcrumb-item :to="{ path: '/course' }">课程</el-breadcrumb-item>
          <el-breadcrumb-item>{{ detailData.courseName }}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card course-top-con">
          <el-row :class="$style['course-d-con']" class="clearfix">
            <el-col :class="$style['d-img']" :span="5">
              <img
                :src="detailData.coverUrl ? renderFileUrl(cdnURL, detailData.coverUrl) : empty"
                class="image"
                :onerror="`onerror=null;src='${empty}'`"
              />
            </el-col>
            <el-col :class="$style['d-info']" :span="19">
              <h3>{{ detailData.courseName }}</h3>
              <el-row>
                <el-col>
                  <el-row class="clearfix">
                    <span>课程类型：{{ detailData.typeTags }}</span>
                  </el-row>
                  <!-- <li :class="$style['d-right']" class="clearfix">
                    <span>{{ detailData.finishNum }}/{{ detailData.totalNum }}</span>
                  </li> -->
                  <el-row class="clearfix">
                    <span>课程简介</span>
                  </el-row>
                  <el-row class="clearfix">
                    <div>
                      <span :class="$style['course-brief']">{{ detailData.courseBrief }}</span>
                    </div>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
            <el-row :class="$style['course-satisfaction']" class="clearfix">
              <el-col>
                <a @click="handleClickFeedback(KEY_LIKE)">
                  <img
                    :src="smail"
                    :class="getIconChange('smail', detailData.studentFeedback === Feedback.LIKE)"
                  />
                </a>
                <span style="margin-right: 20px;">{{ detailData.likeCount }}</span>
                <a @click="handleClickFeedback(KEY_DISLIKE)">
                  <img
                    :src="meh"
                    :class="getIconChange('meh', detailData.studentFeedback === Feedback.DISLIKE)"
                  />
                </a>
                <span>{{ detailData.dislikeCount }}</span>
              </el-col>
            </el-row>
          </el-row>
        </el-card>
      </div>
    </div>
    <div :class="$style['tabs']" class="course-tab">
      <div class="nav wrapper">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程介绍" name="1"></el-tab-pane>
          <el-tab-pane label="章节内容" name="2"></el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div :class="$style['detail-con']">
      <div class="wrapper">
        <el-card class="box-card detail-con-box" v-if="activeName === '1'">
          <div v-html="detailData.courseDesc"></div>
        </el-card>
        <el-card class="box-card detail-con-list" v-if="activeName === '2'">
          <el-collapse v-model="activeKey" v-for="(item, index) in detailData.chapters" v-bind:key="index">
            <!-- 章节列表 -->
            <el-collapse-item :name="index" :title="item.chapterName">
              <!-- 课次列表 -->
              <el-collapse v-model="activeKey" class="list-section" :class="$style['list-con']" v-for="(resource, idRes) in detailData.sections" v-bind:key="idRes">
                <el-collapse-item :name="`${index}-${idRes}`" :title="resource.sectionName" v-if="resource.chapterId === item.id">
                  <!-- 课次附件 -->
                  <ul>
                    <li v-for="(video, idVi) in resource.video" :key="idVi">
                      <a :class="$style['button']" @click="startLearning(resource.id, video)">查看</a>
                      <i :class="iconList.video"></i>{{ video.split(":")[0] }}
                    </li>
                    <li v-for="(file, idFi) in resource.files" :key="idFi">
                      <a :class="$style['button']" @click="startLearning(resource.id, file)">查看</a>
                      <i :class="iconList.courseware"></i>{{ file.split(":")[0] }}
                      <!-- <i
                        :class="
                          resource.resourceType === ('ppt' || 'pdf' || 'txt' || 'docx' || 'doc')
                            ? iconList.courseware
                            : resource.resourceType === ('mp4' || 'avi' || 'mpeg')
                            ? iconList.video
                            : iconList.operation
                        "
                      ></i
                      > -->
                      <!-- <i v-if="resource.resourceKind==1" :class="iconList.video"></i>
                      <i v-if="resource.resourceKind==2" :class="iconList.courseware"></i>
                      <i v-if="resource.resourceKind==3" :class="iconList.operation"></i> -->
                    </li>
                    <div v-for="work in detailData.works" :key="work.id">
                      <li v-if="work.sectionId === resource.id">
                        <a :class="$style['button']" @click="toWorkPage(item.id, work.sectionId, work.id)">做作业</a>
                        <i :class="iconList.operation"></i>{{ work.workName }}
                      </li>
                    </div>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import empty from '@/assets/img/empty.png';
import { CDNURL, PPTVIEWURL, PREVIEWURL } from '@/utils/constants';
import { ElMessage } from 'element-plus';
import smail from '@/assets/img/smail.png';
import meh from '@/assets/img/meh.png';
import bsmail from '@/assets/img/black-smail.png';
import bmeh from '@/assets/img/black-meh.png';
import {isFile, renderFileUrl} from '@/utils/utils';
import * as courseService from '@/services/course';
export default {
  data() {
    return {
      cdnURL: CDNURL,
      smail: smail,
      meh: meh,
      activeName: '1',
      activeKey: [],
      detailData: {},
      paramData: {
        courseId: '',
        chapterId: '',
        sectionId: '',
        workId: '',
      },
      iconList: {
        video: 'el-icon-video-camera',
        courseware: 'el-icon-data-line',
        operation: 'el-icon-reading',
      },
      roomUrl: '',
      empty: empty,
      KEY_LIKE: "like",
      KEY_DISLIKE: "dislike",
      Feedback: {
        'NONE': 0,
        'LIKE': 1,
        'DISLIKE': 2,
      },
    };
  },
  created() {
    const { params = {} } = this.$route || {};
    this.paramData.courseId = params.id;
  },
  mounted() {
    this.getDatas();
  },
  methods: {
      renderFileUrl,
    // 课程数据
    getDatas(callback) {
      const { courseId } = this.paramData;
      const that = this;
      this.$request
        .get(`/studyCourse/queryCourseDetail?id=${courseId}`)
        .then(function(response) {
          if (response.data.code === 0) {
            const { data = {} } = response.data || {};
            that.detailData = data;
            for (let j in that.detailData.sections) {
              if (that.detailData.sections[j].videoUrl) {
                that.detailData.sections[j].video = that.detailData.sections[j].videoUrl.split(",");
              }
              if (that.detailData.sections[j].filesUrl) {
                that.detailData.sections[j].files = that.detailData.sections[j].filesUrl.split(",");
              }
            }
            if (callback) callback();
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // 读取满意度图标样式
    getIconChange(type, value) {
      if (value) {
        if (type === 'smail') this.smail = bsmail;
        else this.meh = bmeh;
      } else {
        if (type === 'smail') this.smail = smail;
        else this.meh = meh;
      }
      return "feedbackIcon";
    },

    // 值减1，最多减到0
    minusOne(value) {
      return (value <= 1) ? 0 : value - 1;
    },

    // 点击满意度评价事件
    async handleClickFeedback (key) {
      // 判断是否登录
      if (localStorage.getItem('token') === null) {
        this.$store.commit('setLoginVisible', true);
        return;
      }
      let _value = -1;
      let _likeCount = this.detailData.likeCount;
      let _dislikeCount = this.detailData.dislikeCount;

      if (key === this.KEY_LIKE) {  // 点击笑脸
        if (this.detailData.studentFeedback === this.Feedback.LIKE) {  // 撤销满意
          _value = this.Feedback.NONE;
          _likeCount = this.minusOne(_likeCount);
        } else {  // 满意
          _value = this.Feedback.LIKE;
          _likeCount += 1;
          if (this.detailData.studentFeedback === this.Feedback.DISLIKE)  // 不满意 -> 满意
            _dislikeCount = this.minusOne(_dislikeCount);
        }
      } else {  // 点击哭脸
        if (this.detailData.studentFeedback === this.Feedback.DISLIKE) {  // 撤销不满意
          _value = this.Feedback.NONE;
          _dislikeCount = this.minusOne(_dislikeCount);
        } else {  // 不满意
          _value = this.Feedback.DISLIKE;
          _dislikeCount += 1;
          if (this.detailData.studentFeedback === this.Feedback.LIKE)  // 满意 -> 不满意
            _likeCount = this.minusOne(_likeCount);
        }
      }
      try {
        const resp = await courseService.set(this.detailData.id, _value);
        const data = resp.data;
        if (data.msg === '操作成功！') {
          this.detailData.studentFeedback = _value;
          this.detailData.likeCount = _likeCount;
          this.detailData.dislikeCount = _dislikeCount;
          ElMessage.success(data.msg);
        }
      } catch {
        ;
      } finally {
        ;
      }
    },

    // tab切换
    handleClick(tab) {
      this.activeName = tab.props.name;
    },

    // 点击开始学习
    startLearning(id, arr) {
      // 判断是否登录
      if (localStorage.getItem('token') === null) {
        this.$store.commit('setLoginVisible', true);
        return;
      }
      const { courseId } = this.paramData;
      this.$request
        .post(`/assess/courseClick/add`, {courseId: courseId, sectionId: id, resourceName: arr.split(':')[0]})
        .then(function() {
            let filename = arr.split(':')[1];
            if (isFile(filename)) {
                // 判断不是 zip, rar, jar, tar, gzip, 7z，tar.gz 等格式时，直接打开
                if (['zip', 'rar', 'jar', 'tar', 'gzip', '7z', 'tar.gz'].includes(filename.split('.')[1])) {
                    window.open(renderFileUrl(PPTVIEWURL, filename));
                } else {
                    window.open(PREVIEWURL + '/onlinePreview?url=' + encodeURIComponent(btoa((renderFileUrl(CDNURL, filename)))), '_blank');
                }
            } else {
                // 判断不是 zip, rar, jar, tar, gzip, 7z，tar.gz 等格式时，直接打开
                if (['zip', 'rar', 'jar', 'tar', 'gzip', '7z', 'tar.gz'].includes(filename.split('.')[1])) {
                    window.open(renderFileUrl(CDNURL, filename));
                } else {
                    window.open(PREVIEWURL + '/onlinePreview?url=' + encodeURIComponent(btoa((renderFileUrl(CDNURL, filename)))), '_blank');
                }
            }
        })
        .catch(function() {
          // console.log(error);
        });

      // const that = this;
      // let url = '/studyCourse/';
      // // if (['ppt', 'pdf'].includes(resourceType)) {
      // if (resourceKind === 2) {
      //   if (['pdf'].includes(resourceType)) {
      //     // 课件跳转控制
      //   url += `startToCourseWare?courseId=${courseId}&resourceKind=${resourceKind}&lessonId=${lessonId}`;

      //   this.$request
      //     .get(url)
      //     .then(function(response) {
      //       const host = CDNURL;

      //       const { data = {} } = response || {};
      //       if (data.code === 0) {
      //         // window.open(host + resource.resourceSrc, '_blank');
      //         window.open('https://www.ideacode.com.cn/pdf/web/viewer.html?file=' + resource.resourceSrc, '_blank');
      //       }
      //     })
      //     .catch(function(error) {
      //       console.log(error);
      //     });
      //   } else {
      //     window.open(CDNURL + resource.resourceSrc, '_blank');
      //   }
      // // } else if (['mp4', 'avi', 'mpeg'].includes(resourceType)) {
      // } else if (resourceKind === 1) {
      //   // 视频跳转控制
      //   url += `startLearning?courseId=${courseId}&resourceKind=${resourceKind}&lessonId=${lessonId}`;

      //   this.$request
      //     .get(url)
      //     .then(function(response) {
      //       const { data = {} } = response || {};
      //       if (data.code === 0) {
      //         // that.$router.push({
      //         //   path: '/course/video',
      //         //   query: { courseId: courseId, lessonId: lessonId, resourceSrc: resourceSrc },
      //         // });
      //         window.open('./video?courseId=' + courseId + '&lessonId=' + lessonId + '&resourceSrc=' + resourceSrc);
      //       }
      //     })
      //     .catch(function(error) {
      //       console.log(error);
      //     });
      // } else {
      //   // 作业跳转控制
      //   url += `/startToWork?courseId=${courseId}&resourceKind=${resourceKind}&lessonId=${lessonId}`; // 作业请求接口地址

      //   this.$request
      //     .get(url)
      //     .then(function(response) {
      //       const { data = {} } = response || {};

      //       if (data.code === 0) {
      //         that.$router.push({
      //           path: '/course/homework/' + resourceSrc,
      //         });
      //         // window.open('./homework/' + resourceSrc);
      //       }
      //     })
      //     .catch(function(error) {
      //       console.log(error);
      //     });
      // }
    },

    toWorkPage(chapterId, sectionId, workId) {
      // 判断是否登录
      if (localStorage.getItem('token') === null) {
        this.$store.commit('setLoginVisible', true);
        return;
      }
      const { courseId } = this.paramData || {};
      window.open('/course/homework/?courseId='+courseId+'&chapterId='+chapterId+'&sectionId='+sectionId+'&workId='+workId);
      // this.$router.push({
      //   path: `/course/homework/`,
      //   query: {
      //     courseId: courseId,
      //     chapterId: chapterId,
      //     sectionId: sectionId,
      //     workId: workId,
      //   }
      // });
    }
  },
};
</script>
<style lang="scss">
.breadcrumb {
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover {
    color: #fff;
  }
}
.course-top-con {
  .el-rate__item {
    margin: 13px 0px;
  }
}
.feedbackIcon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.course-tab {
  .el-tabs__active-bar.is-top {
    background-color: $color-primary;
  }
  .el-tabs__item:hover {
    color: $color-primary;
    cursor: pointer;
  }
  .el-tabs__item.is-active {
    color: $color-primary;
  }
}
.detail-con-box,
.detail-con-list {
  .el-card {
    border-radius: 20px;
  }
  .el-card__body {
    padding: 45px;
    min-height: 300px;
    .page-item {
      padding-bottom: 0px;
    }
  }
  .el-collapse {
    border-top: none;
    border-bottom: none;
  }
  .el-collapse-item {
    .el-collapse-item__header {
      padding: 0 10px 0 0;
      height: 53px;
      font-size: 16px;
      font-weight: bold;
      line-height: 53px;
      border-bottom: none;
    }
    .el-collapse-item__wrap {
      border-bottom: none;
    }
  }
  .list-section {
    .el-collapse-item {
      .el-collapse-item__header {
        padding: 0 10px 0 30px;
        height: 53px;
        font-size: 15px;
        font-weight: normal;
        line-height: 53px;
      }
    }
  }
}
</style>
<style lang="scss" module>
@import './index.scss';
</style>
