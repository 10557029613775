
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  props: ['listdata'],
  setup() {
    ;
  },
});
