// 政务云配置数据
// export const CDNURL = 'http://10.204.112.36:8082/api/sys/common/static/';
// export const LIVEURL = 'https://www.ideacode.com.cn/live/';
// export const PROGRAMURL = 'http://10.204.112.36:8082/';
// export const MBPROGRAMURL = 'http://10.204.112.36:8082/redirect.html';
// export const PPTVIEWURL = 'https://vip.ow365.cn/?i=31270&furl=http://110.42.238.39:8081/jeecgboot/sys/common/static/';
// export const MBotURL1 = 'http://10.204.112.36:8082/platform.html?type=mBot-p';
// export const MBotURL2 = 'http://10.204.112.36:8082/platform.html?type=mBot-s';
// export const MakeCodeURL = 'http://10.204.112.36:8082/platform.html?type=mc';
// export const MixlyURL = 'http://10.204.112.36:8082/platform.html?type=mix';
// export const KBlockURL = 'http://10.204.112.36:8082/platform.html?type=kb';
// export const ROBOMASTERURL = 'http://10.204.112.36:8082/platform.html?type=robo';
// export const KenBlockURL = 'http://10.204.112.36:8082/platform.html?type=ken';
// export const CocoKitURL = 'http://10.204.112.36:8082/platform.html?type=coco';
// export const UbtRobotURL = 'http://10.204.112.36:8082/platform.html?type=ubt';
// export const MPythonURL = 'http://10.204.112.36:8082/platform.html?type=gd';
// export const AILabURL = 'http://10.204.112.33/';
// export const DobotLabURL = 'http://10.204.112.36:8082/platform.html?type=dobot';
// export const ArduinoURL = 'http://10.204.112.36:8082/platform.html?type=arduino';
// export const MindURL1 = 'http://10.204.112.36:8082/platform.html?type=mind+';
// export const MindURL2 = 'http://10.204.112.36:8082/platform.html?type=mind';
// export const baseURL = 'http://10.204.112.36:8082/api';
// export const RESOURCEURL = 'http://10.204.112.36:8082/api';

//  测试服配置数据
export const CDNURL = 'https://cdn2.1717youxue.com/static/meta/'; // todo 1
export const LIVEURL = 'https://www.ideacode.com.cn/live/';
export const PROGRAMURL = 'https://code.metaape.com.cn/'; // todo 1
export const MBPROGRAMURL = 'https://code.metaape.com.cn/redirect.html';
export const PPTVIEWURL = 'https://cdn2.1717youxue.com/static/meta/';

export const PREVIEWURL = 'https://preview.1717youxue.com';
export const MBotURL1 = 'https://www.metaape.com.cn/platform.html?type=mBot-p';
export const MBotURL2 = 'https://www.metaape.com.cn/platform.html?type=mBot-s';
export const MakeCodeURL = 'https://www.metaape.com.cn/platform.html?type=mc';
export const MixlyURL = 'https://www.metaape.com.cn/platform.html?type=mix';
export const KBlockURL = 'https://www.metaape.com.cn/platform.html?type=kb';
export const ROBOMASTERURL = 'https://www.metaape.com.cn/platform.html?type=robo';
export const KenBlockURL = 'https://www.metaape.com.cn/platform.html?type=ken';
export const CocoKitURL = 'https://www.metaape.com.cn/platform.html?type=coco';
export const UbtRobotURL = 'https://www.metaape.com.cn/platform.html?type=ubt';
export const MPythonURL = 'https://www.metaape.com.cn/platform.html?type=gd';
export const AILabURL = 'http://101.200.211.232/';
export const DobotLabURL = 'https://www.metaape.com.cn/platform.html?type=dobot';
export const ArduinoURL = 'https://www.metaape.com.cn/platform.html?type=arduino';
export const MindURL1 = 'https://www.metaape.com.cn/platform.html?type=mind+';
export const MindURL2 = 'https://www.metaape.com.cn/platform.html?type=mind';
// export const baseURL = 'http://110.42.238.39:8082/api';
export const baseURL = 'https://code.metaape.com.cn/api'; // todo 1
export const RESOURCEURL = 'https://code.metaape.com.cn/api'; // todo 1
// export const baseURL = 'http://localhost:9091/api';
// export const RESOURCEURL = 'http://localhost:9091/api';
