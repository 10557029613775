<template>
  <div :class="$style['tabs']" class="create-tab">
    <div class="nav wrapper">
      <el-tabs v-model="activeName">
        <el-tabs v-model="activeName">
          <el-tab-pane
            :label="item.label"
            :name="item.name"
            v-for="item in navData"
            v-bind:key="item.name"
          ></el-tab-pane>
        </el-tabs>
      </el-tabs>
      <el-dropdown :class="$style['dropdown2']">
        <el-button type="primary"> 开始创作<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="clickCreate('sc')">Scratch</el-dropdown-item>
            <el-dropdown-item @click="clickCreate('py')">Python</el-dropdown-item>
            <el-dropdown-item @click="clickCreate('cpp')">C++</el-dropdown-item>
<!--            <el-dropdown-item @click="clickCreate('micro:bit')">Micro:bit</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickCreate('ai')">AILab</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickCreate('arduino')">Arduino</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickCreate('mind+')">Mind+</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickCreate('dobot')">DobotLab</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickCreate('mBot-p')">童心制物(python)</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickCreate('mBot-s')">童心制物(图形化)</el-dropdown-item>-->
<!--            &lt;!&ndash; <el-dropdown-item @click="clickCreate('mc')">微软</el-dropdown-item> &ndash;&gt;-->
<!--            <el-dropdown-item @click="clickCreate('mix')">Mixly</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickCreate('kb')">Kittenblock</el-dropdown-item>-->
          </el-dropdown-menu>
        </template>
      </el-dropdown>
<!--      <el-dropdown :class="$style['dropdown2']">-->
<!--        <el-button type="primary"> 编程工具<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i> </el-button>-->
<!--        <template #dropdown>-->
<!--          <el-dropdown-menu>-->
<!--            <el-dropdown-item @click="clickDownload('arduino')">Arduino</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickDownload('mind+')">Mind+</el-dropdown-item>-->
<!--            &lt;!&ndash; <el-dropdown-item @click="clickDownload('robo')">大疆</el-dropdown-item> &ndash;&gt;-->
<!--            <el-dropdown-item @click="clickDownload('ken')">乐智</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickDownload('coco')">可可乐博</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickDownload('ubt')">优必选</el-dropdown-item>-->
<!--            <el-dropdown-item @click="clickDownload('gd')">盛思</el-dropdown-item>-->
<!--          </el-dropdown-menu>-->
<!--        </template>-->
<!--      </el-dropdown>-->
      <el-dialog
        :title="title"
        v-model="createVisible"
        width="500px"
        center
        custom-class="create-pop"
      >
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item v-if="title === 'DobotLab'" class="form-item" prop="createway">
            <el-radio-group v-model="createway">
              <el-radio label="上传" />
              <el-radio label="编程" />
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="createway === '上传'" label="作品名称：" :label-width="formLabelWidth" class="form-item" prop="workname">
            <el-input v-model="ruleForm.workname" size="medium" placeholder="请输入作品名称"></el-input>
          </el-form-item>
          <el-form-item v-if="createway === '上传'" label="代码上传：" :label-width="formLabelWidth" class="form-item" prop="fileList">
            <el-upload
              class="upload-demo"
              :action="api"
              v-model:file-list="ruleForm.fileList"
              :headers="header"
              :data="bizPath"
              :on-success="uploadSuccess"
              :on-remove="uploadRemove"
              :before-upload="beforeUpload"
              :on-preview="handlePreview"
              :limit="1"
              :on-exceed="handleExceed"
            >
              <el-button type="primary" size="small">上传</el-button>
              <template #tip>
                <div class="el-upload__tip">
                  最多上传1个文件， 大小不超过50MB
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="createway === '编程'" label="编程环境：" :label-width="formLabelWidth" class="code-item form-item" prop="program">
            <el-button type="primary" size="small" class="btn-code" @click="program"
              >开始编程</el-button
            >
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleCancel">关 闭</el-button>
            <el-button v-if="createway !== '编程'" type="primary" @click="handleSuccess(title, 'ruleForm')">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
  <!-- <div class="bg"> -->
  <div>
    <div class="wrapper">
      <el-card class="filter-box ">
        <div class="filter-items clearfix">
          <div class="label">作品类型：</div>
          <el-radio-group v-model="radioValue" class="filter-item" size="small" @change="radioChangeHandle">
            <el-radio-button label="0">全部</el-radio-button>
            <el-radio-button label="1">Scratch</el-radio-button>
            <el-radio-button label="2">Python</el-radio-button>
            <el-radio-button label="3">C++</el-radio-button>
<!--            <el-radio-button label="4">Micro:bit</el-radio-button>-->
<!--            <el-radio-button label="5">AILab</el-radio-button>-->
<!--            <el-radio-button label="6">Arduino</el-radio-button>-->
<!--            <el-radio-button label="7">Mind+</el-radio-button>-->
<!--            <el-radio-button label="8">DobotLab</el-radio-button>-->
          </el-radio-group>
        </div>
        <div class="filter-items clearfix">
            <div class="label">作品名称：</div>
            <div class="filter-item">
              <el-input
                placeholder="请输入作品名称"
                v-model="workName"
                size="small"
                class="search"
                @keydown.enter="searchQuery"
              >
                <!-- <template #suffix> <i class="el-icon-search el-input__icon" @click="searchQuery"> </i> </template> -->
              </el-input>
            </div>
            <el-button type="primary" size="small" style="padding-top: 5px; margin-left: 20px;" @click="searchQuery">查询</el-button>
            <el-button type="primary" size="small" style="padding-top: 5px;" @click="searchReset">刷新</el-button>
          </div>
      </el-card>
      <div class="list-box">
        <el-row
          class="list"
          :class="$style['list']"
          :gutter="30"
          v-if="listDatas.records && listDatas.records.length > 0"
        >
          <el-col :span="30" v-for="o in listDatas.records" :key="o">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <img
                :src="
                  o.coverUrl
                    ? renderFileUrl(cdnUrl, o.coverUrl)
                    : empty
                "
                class="image"
                :onerror="`onerror=null;src='${empty}'`"
                @click="editCode"
                :data-workName="o.workName"
                :data-workId="o.id"
                :data-workType="o.workType"
                :data-workUrl="o.workUrl"
              />
              <div class="txt-box">
                <span>{{ o.workName }}</span>
                <p class="list-msg ">
                  <span class="msg-left">{{ workTypeName[o.workType] }}</span
                  ><span class="msg-right">{{ $moment(o.createTime).format('YYYY/MM/DD') }}</span>
                </p>
                <div class="bottom-btn">
                  <div>
                    <svg-icon icon-class="icondelete" class="list-delete"></svg-icon>
                    <span @click="openDeleteDatas" :data-id="o.id">删除</span>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          v-model:currentPage="pageNo"
          background
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="listDatas.total"
          class="page-item"
          v-if="listDatas.records && listDatas.records.length > 0"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="删除确认" v-model="dialogVisible" width="300px" :before-close="handleDeleteClose">
      <span>请确认是否要删除作品？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleDeleteClose">取 消</el-button>
          <el-button type="primary" @click="deleteDatas">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {ElMessage} from 'element-plus';
import {
    AILabURL,
    ArduinoURL,
    CDNURL,
    CocoKitURL,
    DobotLabURL,
    KBlockURL,
    KenBlockURL,
    MBotURL1,
    MBotURL2,
    MBPROGRAMURL,
    MindURL1,
    MindURL2,
    MixlyURL,
    MPythonURL,
    PROGRAMURL,
    RESOURCEURL,
    UbtRobotURL
} from '@/utils/constants';
import empty from '@/assets/img/empty.png';
import * as uploadService from '@/services/upload';
import {renderFileUrl} from "@/utils/utils";

export default {
  data() {
    return {
      // tabs 选中
      activeName: '1',
      // 表单数据
      ruleForm: {
        workname: '',
        fileList: [],
      },
      // 表单规则
      rules: {
        workname: [{ required: true, message: '请输入作品名称！', trigger: 'blur' }],
        fileList: [{ required: true, message: '请上传代码文件！', trigger: 'blur' }],
      },
      // 单选框默认选中
      createway: '上传',
      // tabs 数据
      navData: [{ label: '我的作品', name: '1' }],
      // 类型 选中
      radioValue: '0',
      // 类型参数
      workType: '',
      // 作品名称
      workName: '',
      // 分页选中
      pageNo: 1,
      // 分页条目数
      pageSize: 10,
      // 列表数据
      listDatas: {},
      // 弹窗标题
      title: '',
      // 标题数据
      titleList: {'dobot': 'DobotLab', 'arduino': 'Arduino', 'mind+': 'Mind+'},
      // label宽度
      formLabelWidth: '100px',
      // 上传请求URL
      api: RESOURCEURL + '/sys/common/upload',
      // 上传请求头部
      header: {
        "Authorization": localStorage.getItem('token')
      },
      // 上传额外参数
      bizPath: {biz: 'work/file'},
      // 封面URL
      coverUrl: '',
      // 创作弹窗
      createVisible: false,
      // 删除弹窗
      dialogVisible: false,
      // workId ? 编辑 : 添加
      workId: '',
      // 删除id
      deleteId: '',
      cdnUrl: CDNURL,
      programUrl: PROGRAMURL,
      // 空图片
      empty: empty,
      workTypeName: {1: "Scratch", 2: "Python", 3: "C++", 4: "Micro:bit", 5: "AILab", 6: "Arduino", 7: "Mind+", 8: "DobotLab"},
      langName: {1: "sc", 2: "py", 3: "cpp", 4: "micro:bit", 5: "ai", 6: "arduino", 7: "mind+", 8: "dobot"},
    };
  },
  mounted() {
    this.getDatas();
  },
  methods: {
      renderFileUrl,
    // 获取数据
    getDatas() {
      const that = this;
      if (this.radioValue === "0") {
        this.workType = "";
      } else {
        this.workType = this.radioValue;
      }
      this.$request
      .get(`/studyCreate/list?pageNo=${this.pageNo}${this.workType ? '&workType=' + this.workType : ''}${this.workName ? '&workName=' + encodeURIComponent(this.workName) : ''}`)
        .then(function(response) {
          const { data = {} } = response.data || {};
          that.listDatas = { ...data };
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 打开删除弹窗
    openDeleteDatas(e) {
      const { dataset = {} } = e.target || {};
      console.log(e.target)
      console.log(dataset)
      this.deleteId = dataset.id;
      this.dialogVisible = true;
    },
    // 编辑代码
    async editCode(e) {
      const { dataset = {} } = e.target || {};
      console.log(dataset);
      const token = localStorage.getItem('token');
      this.workId = dataset.workid;
      if (dataset.worktype === '1' || dataset.worktype === '2' || dataset.worktype === '3') {
        let langName = this.langName[dataset.worktype];
        const url = `${PROGRAMURL}${langName}/?platform=1&ide=${langName}&token=${token}&workId=${this.workId}`;
        window.open(url, '_blank');
      } else if (dataset.worktype === '4') {
        const url = `${MBPROGRAMURL}?platform=1&token=${token}&workId=${this.workId}`;
        window.open(url, '_blank');
      } else if (dataset.worktype === '5') {
        console.log('######');
      } else {
        let langName = this.langName[dataset.worktype];
        this.title = this.titleList[langName];
        this.createway = '上传';
        this.ruleForm.workname = dataset.workname;
        this.ruleForm.fileList = [];
        if (dataset.workurl && dataset.workurl.length > 0) {
          this.ruleForm.fileList.push({
            name: dataset.workurl.substring(dataset.workurl.lastIndexOf('/') + 1),
            url: dataset.workurl,
          });
        }
        this.createVisible = true;
      }
    },
    handleDeleteClose() {
      this.dialogVisible = false;
      this.deleteId = '';
    },
    // 删除数据
    deleteDatas() {
      const that = this;
      this.$request
        .delete(`/studyCreate/delete?id=${this.deleteId}`)
        .then(function() {
          ElMessage.success({
            message: '删除成功',
            type: 'success',
          });
          that.dialogVisible = false;
          that.deleteId = '';
          that.getDatas();
        })
        .catch(function() {
          that.dialogVisible = false;
        });
    },
    // 分页
    handleCurrentChange(val) {
      this.getDatas();
      this.pageNo = val;
    },
    handleCancel() {
      this.createVisible = false;
    },
    async handleSuccess(type, formName) {
      if (type === 'DobotLab') {
        this.coverUrl = 'work/cover/doBotCover.png';
        this.workType = 8;
      }
      if (type === 'Arduino') {
        this.coverUrl = 'work/cover/arduinoCover.png';
        this.workType = 6;
      }
      if (type === 'Mind+') {
        this.coverUrl = 'work/cover/mindPlusCover.png';
        this.workType = 7;
      }
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.workId) {
            const resp = uploadService.add(this.coverUrl, this.ruleForm.fileList[0].url, this.ruleForm.workname, this.workType);
            ElMessage.success({
              message: '添加成功',
              type: 'success',
            });
            this.createVisible = false;
            resp.then(function(res) {
              if (res.data.code === 0) {
                console.log(res.data)
                that.getDatas();
              }
            })
          } else {
            const resp = uploadService.edit(that.coverUrl, this.ruleForm.fileList[0].url, this.ruleForm.workname, this.workId, that.workType);
            ElMessage.success({
              message: '编辑成功',
              type: 'success',
            });
            this.createVisible = false;
            resp.then(function(res) {
              if (res.data.code === 0) {
                that.getDatas();
              }
            })
          }
        }
      })
    },
    radioChangeHandle() {
      if (this.radioValue === "5") {
        window.open(window.open(AILabURL + JSON.parse(localStorage.getItem('userInfo')).id))
      } else {
        this.workName = "";
        this.pageNo = 1;
        this.getDatas();
      }
    },
    // 查询事件
    searchQuery() {
      this.pageNo = 1;
      this.getDatas();
    },
    // 重置事件
    searchReset() {
      this.workName = "";
      this.pageNo = 1;
      this.getDatas();
    },
    // 开始创作
    clickCreate(langName) {
      const token = localStorage.getItem('token');
      if (langName === 'sc' || langName === 'py' || langName === 'cpp') {
        const url = `${PROGRAMURL}${langName}/?platform=1&ide=${langName}&token=${token}`;
        window.open(url);
      } else if (langName === 'micro:bit') {
        window.open(MBPROGRAMURL + '?platform=1&token=' + token);
      } else if (langName === 'ai') {
        window.open(AILabURL + JSON.parse(localStorage.getItem('userInfo')).id);
      } else if (langName === 'mBot-p') {
        window.open(MBotURL1);
      } else if (langName === 'mBot-s') {
        window.open(MBotURL2);
      // } else if (langName === 'mc') {
      //   window.open(MakeCodeURL);
      } else if (langName === 'mix') {
        window.open(MixlyURL);
      } else if (langName === 'kb') {
        window.open(KBlockURL);
      } else if (langName === 'mind+') {
        window.open(MindURL2);
      } else {
        this.workId = '';
        this.title = this.titleList[langName];
        this.ruleForm.workname = '';
        this.ruleForm.fileList = [];
        this.createway = '上传';
        this.createVisible = true;
      }
    },
    // 打开编程工具
    program() {
      window.open(DobotLabURL);
    },
    // 下载编程工具
    clickDownload(langName) {
      if (langName === "arduino") {
        window.open(ArduinoURL);
      }
      if (langName === "mind+") {
        window.open(MindURL1);
      }
      // if (langName === "robo") {
      //   window.open(ROBOMASTERURL);
      // }
      if (langName === "ken") {
        window.open(KenBlockURL);
      }
      if (langName === "coco") {
        window.open(CocoKitURL);
      }
      if (langName === "ubt") {
        window.open(UbtRobotURL);
      }
      if (langName === "gd") {
        window.open(MPythonURL);
      }
    },
    // 上传成功
    uploadSuccess(uploadFile, uploadFiles) {
      this.ruleForm.fileList.push({
        name: uploadFiles.name,
        url: uploadFile.msg,
      });
    },
    // 删除上传文件
    uploadRemove() {
      this.ruleForm.fileList = [];
    },
    beforeUpload(rawFile) {
      if (rawFile.size / 1024 / 1024 > 50) {
        ElMessage.warning(
          '上传失败：文件大小不能超过 50 MB! 请重新上传。'
        );
        return false;
      }
      return true;
    },
    handlePreview(uploadFile) {
      console.log(uploadFile);
      if (uploadFile && uploadFile.url)
        // window.open(RESOURCEURL + "/sys/common/static/" + uploadFile.url);
        window.open(renderFileUrl(CDNURL, uploadFile.url));
    },
    // 超出文件限制
    handleExceed(files, uploadFiles) {
      ElMessage.warning(
        '上传失败：最多上传1个文件! 请删除后重新上传。'
      );
    },
  },
};
</script>
<style lang="scss">
.create-tab {
  .el-tabs__active-bar.is-top {
    background-color: $color-primary;
  }
  .el-tabs__item:hover {
    color: $color-primary;
    cursor: pointer;
  }
  .el-tabs__item.is-active {
    color: $color-primary;
  }
}
.el-dialog.create-pop {
  border-radius: 10px;
  overflow: hidden;
  .el-dialog__headerbtn {
    .el-dialog__close {
      font-size: 24px;
      color: #fff;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .el-dialog__body {
    padding-bottom: 0;
  }
  .el-dialog__header {
    background-color: $color-primary;
    .el-dialog__title {
      color: #fff;
    }
  }
  .form-item {
    color: #fff;
    .el-input {
      width: 300px;
    }
    .el-upload__tip {
      color: red;
      font-size: 14px;
    }
  }
  .code-item {
    .el-input {
      width: 180px;
      vertical-align: middle;
    }
    img {
      display: inline-block;
      margin-left: 10px;
      height: 36px;
      width: 110px;
      vertical-align: middle;
    }
  }
  .el-radio-group {
    width: 100%;
    text-align: center;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: $color-primary;
    background: $color-primary;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $color-primary;
  }
  .el-radio__inner:hover {
    border-color: $color-primary;
  }
}
</style>
<style lang="scss" module>
@import 'index.scss';
</style>
