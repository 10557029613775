<template>
  <div :class="$style['user-header']">
    <div class="wrapper">
      <div :class="$style['username']">
        <img
          :src="userInfo.avatar ? userInfo.avatar : defaultImg"
          alt=""
          :onerror="`onerror=null;src='${defaultImg}'`"
        />
        <p>{{ userInfo.realname }}</p>
      </div>
      <div :class="$style['nav']">
        <span v-for="item in navDatas" v-bind:key="item.link"
          ><router-link :to="item.link" :class="routePath === item.link ? $style['active'] : ''">{{
            item.name
          }}</router-link></span
        >
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>
<script>
import defaultImg from '@/assets/img/user.png';
export default {
  data() {
    return {
      // 个人中心导航数据
      navDatas: [
        { name: '创作中心', link: '/user/create' },
        { name: '我的课程', link: '/user/course' },
        { name: '个人中心', link: '/user' },
      ],
      // 当前tab
      routePath: '',
      // 用户数据
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      // 默认头像
      defaultImg: defaultImg,
    };
  },
  watch: {
    $route: function() {
      const { path } = this.$route || {};
      this.routePath = path;
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      const { path } = this.$route || {};
      this.routePath = path;
    } else {
      this.$router.push({ path: `/` });
    }
  },
};
</script>
<style lang="scss" module>
.user-header {
  height: 430px;
  background-color: $color-primary;
}
.username {
  // color: #fff;
  padding-top: 70px;
  text-align: center;

  img {
    display: inline-block;
    border: 4px solid #fff;
    width: 170px;
    height: 170px;
    border-radius: 100px;
    background-color: #fff;
  }
  p {
    padding-top: 15px;
    font-size: 18px;
    color: $text-color-inverse;
  }
}
.nav {
  margin-top: 80px;
  text-align: center;
  span {
    display: inline-block;
    text-align: center;
    width: 20%;
    font-size: 16px;
    color: $text-color-inverse;
  }
  a {
    display: inline-block;
    width: 100px;
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    color: $text-color-inverse;
    transition: 0.3s all;
    &:hover,
    &.active {
      color: $color-primary;
      background-color: $bg-color;
    }
  }
}
</style>
